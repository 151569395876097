import { useState } from 'react';
import {
    Box, Dialog, DialogContent, DialogTitle, Typography, Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OtherServicesStyle from './OtherServices.style';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { CONTACT_SUPPORT, MESSAGES } from '../../../helper/constants';
import toast from 'react-hot-toast';

interface Props {
    open: boolean,
    handleClose: () => void,
}
function OtherServices(props: Props) {
    const { open, handleClose } = props;
    const classes = OtherServicesStyle();
    const [idOpen, setIdOpen] = useState(false);
    const idHandle = () => {
        // Click it and it will give you the email address of the virtual pay visa debit card
        setIdOpen(true);
    };
    const closeIdHandle = () => {
        // Email addresses will be closed on copies of email addresses
        const emailToCopy = CONTACT_SUPPORT.VISA_PAY_CONTACT;
        navigator.clipboard.writeText(emailToCopy)
            .then(() => {
                toast.success(MESSAGES.EMAIL_COPY);
            })
            .catch((error) => {
                toast.error(error as string);
            });
        setIdOpen(false);
    };
    return (
        <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
            <DialogTitle className={classes.dilogBoxSection} variant="h2">
                Other Services
                <div>
                    <Typography variant="h5"><span><CloseIcon onClick={handleClose} /></span></Typography>
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialogBody}>
                <Box className={idOpen ? classes.backgroundInsideBoxStyle : classes.insideBoxStyle}>
                    <Box className={classes.virtualInsideBoxStyle}>
                        <Typography variant="h4">Virtual Pay Visa Debit</Typography>
                        <Button onClick={idHandle}>Contact Sales</Button>
                    </Box>
                    {idOpen
                        ? (
                            <Box className={classes.virtualEmailBoxStyle}>
                                <Typography variant="h6">{CONTACT_SUPPORT.VISA_PAY_CONTACT}</Typography>
                                <span><ContentCopyOutlinedIcon onClick={closeIdHandle} /></span>
                            </Box>
                        )
                        : null}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default OtherServices;

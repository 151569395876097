import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const partnerReferCss = makeStyles((theme:Theme) => ({
    container: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '30px 28px 10px 28px',
        },
        background: '#eef8ff',
        maxWidth: '850px',
        margin: '0 auto',
        height: '100vh',
        zIndex: '0',
        position: 'relative',
        '& ul': {
            listStyle: 'none',
            marginTop: '20px',
            '& li': {
                display: 'inline-block',
                marginRight: '7px',
            },
        },
    },
    box: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '14px',
        },
        border: '1px solid rgba(211, 211, 211, 1)',
        boxShadow: '0px 4px 9.100000381469727px 0px rgba(109, 109, 109, 0.25)',
        background: '#fff',
        borderRadius: '5px',
        marginBottom: '24px',
        '& h2': {
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
            color: '#0A2A5B',
            fontSize: '24px',
            fontWeight: '500',
            letterSpacing: '0.5px',
        },
        '& p': {
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '12px',
            },
            fontSize: '14px',
            lineHeight: '23px',
            letterSpacing: '0.5px',
        },
    },
    box1: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '14px 12px',
        },
        padding: '24px',
        maxWidth: '635px',
        margin: '40px auto 24px',
    },
    textStyle: {
        marginBottom: '18px !important',
        textAlign: 'center',
    },
    paragraph: {
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '6px',
        },
        color: '#606060',
        marginTop: '14px !important',
        display: 'flex',
        gap: '35px',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    warningStyle: {
        color: 'rgba(179, 129, 0, 1) !important',
        fontSize: '14px !important',
        marginTop: '7px',
        display: 'block',
        '& svg': {
            fontSize: '20px',
            verticalAlign: 'middle',
            marginRight: '8px',
        },
    },
    link: {
        color: '#0A2A5B',
    },
    wrapInput: {
        '& fieldset': {
            border: '1px solid rgba(198, 198, 198, 1) !important',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '79%',
        },
        '& input': {
            padding: '11.5px 14px',
        },
    },
    btnCopy: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '9px 2px !important',
            minWidth: '50px',
        },
        padding: '9px 20px !important',
        '& svg': {
            display: 'none',
            [theme.breakpoints.between('xs', 'sm')]: {
                display: 'block',
            },
        },
    },
    clickBtn: {
        // marginTop: '13px !important',
        padding: '7px 19px !important',
        fontSize: '15px !important',
    },
    btnText: {
        display: 'block',
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'none',
        },
        lineHeight: '24px !important',
        textTransform: 'capitalize',
    },
    box2: {
        padding: '6px 24px',
        maxWidth: '635px',
        margin: '0 auto 24px',
        '& h2': {
            [theme.breakpoints.between('xs', 'sm')]: {
                width: '85%',
            },
            display: 'inline-block',
            width: '92%',
            letterSpacing: '0.5px',
        },
        '& span': {
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '17px',
            },
            display: 'inline-block',
            padding: '3px 12px',
            fontSize: '24px',
            borderRadius: '20px',
            color: '#1E7F41',
            background: '#DEFFEA',
        },
    },
    box3: {
        maxWidth: '686px',
        margin: '0 auto 24px',
        '& th': {
            [theme.breakpoints.between('xs', 'sm')]: {
                whiteSpace: 'nowrap',
            },
            background: '#F2F2F2',
            padding: '10px',
            color: '#7E7E7E',
        },
        '& th:first-child': {
            width: '150px',
            paddingLeft: '24px',
        },
        '& td': {
            padding: '10px',
        },
        '& td:first-child': {
            paddingLeft: '24px',
        },
    },
    heading: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '10px 12px',
        },
        padding: '15px 22px',
    },
    copyButton: {
        color: '#fff !important',
        height: '23px !important',
        fontSize: '15px !important',
    },
    textColour: {
        color: 'green',
        fontSize: '12px',
    },
    dashboardLink: {
        marginTop: '7px',
        '& a': {
            color: '#eee',
            textDecoration: 'underline',
            fontSize: '14px',
            transition: '.6s',
            '&:hover': {
                fontSize: '15px',
                '& svg': {
                    fontSize: '20px',
                },
            },
        },
        '& svg': {
            verticalAlign: 'middle',
            fontSize: '18px',
            transition: '.6s',
        },
    },
    formHeader: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '20px 43px',
            textAlign: 'center',
        },
        background: 'linear-gradient(to right,  #0a2a5b 0%,#1968b6 100%)',
        padding: '40px 43px',
        borderRadius: '5px',
        zIndex: '0',
        position: 'relative',
        maxWidth: '764px',
        margin: '0 auto',
        '&  h4': {
            color: '#fff',
            fontSize: '24px',
            fontWeight: '500',
        },
    },
}));

export default partnerReferCss;

import { gql } from 'graphql-request';

export const ADD_CUSTOMER_WALLET = () => gql`
  mutation (
    $network: String!
    $address: String!
  ) {
    add_customer_wallet(
      data: {
        network: $network
        address: $address
      }
    ) {
      success
      message
    }
  }
`;

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
            {/* for enabling dev tools */}
            {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
    </StrictMode>,
);

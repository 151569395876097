export const BREAKPOINTS = {
    values: {
        xs: 0,
        sm: 767,
        md: 1024,
        lg: 1450,
        xl: 1600,
    },
};

export const IS_MOBILE = window.innerWidth <= 767;

// Defines various paths used throughout the application
export const PATH_AUTH = {
    auth: '/',
    children: {
        login: '/login',
        signup: '/signup/:referralCode?',
        forgotPassword: '/forgot-password',
        ChangePassword: '/change-password',
        resetPassword: '/reset-password',
        invalidRequest: '/invalid-request',
    },
};

// Paths related to onboarding
export const PATH_ONBOARDING = {
    root: '/onboarding',
};

// Paths related to OTC trading
export const PATH_OTC = {
    dashboard: '/dashboard',
    tradeorder: '/trade-order',
    partnerrefer: '/referral-program',
};

// Paths related to user profile
export const PATH_PROFILE = {
    // profile: '/profile',
    profile: '/account',
};

// Paths related to admin features
export const PATH_ADMIN = {
    userdetails: '/userdetails',
};

// Paths related to the admin panel
export const PATH_ADMINPANEL = {
    adminpanel: '/',
    children: {
        dashboard: '/admin-dashboard',
        library: '/user-library',
        userdetails: '/user-library/:userId',
    },
};

export const PATH_HOMEPAGE = {
    homePage: '/home-page',
};

import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import partnerrefercss from './PartnerRefer.style';
// import toast from 'react-hot-toast';
// import { useGQLQuery } from '../../../hooks/useGQLQuery';
// import { QueryErrorResponse } from '../../../types/UserDetailType';
import { BASE_URL, SUBJECT } from '../../../helper/constants';
// import referralQueries from '../../../queries/referral';
import { IReferralData } from '../../../interfaces/Referral';
import { getDecodedItemDataFromLocalStorage, JSONParse } from '../../../helper/storageHelper';
import { ILoginUserData } from '../../../interfaces/Auth';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { decryptString } from '../../../helper/encryptionHelper';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { PATH_OTC } from '../../../routers/path';

function PartnerRefer() {
    const classes = partnerrefercss();
    const [copiedUrl, setCopiedUrl] = useState<string>('');
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const userDataString = getDecodedItemDataFromLocalStorage('userData');
    const userData: ILoginUserData | null = userDataString ? JSONParse<ILoginUserData>(userDataString) : null;
    const referralData: IReferralData[] = [];
    const successfulReferral = 0;
    // Handle copy functionality
    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    };

    // useEffect(() => {
    //     if (listData && listData.adminUserReferrals) {
    //         setReferralData(listData.adminUserReferrals.data);
    //         setSuccessfulReferral(listData.adminUserReferrals.pageInfo?.total_count);
    //     }
    // }, [listData]);

    useEffect(() => {
        const selfReferralCode: string = userData?.self_referral_code_name || userData?.self_referral_code || '';
        setCopiedUrl(selfReferralCode);
    }, [userData?.self_referral_code, userData?.self_referral_code_name]);

    const sub = SUBJECT;
    const emailBody = `Hello Admin,
I hope this message finds you well. I am writing to request a custom referral link for my account. 

Please find my details below:
Name: ${userData?.full_name || 'N/A'}
Referral Code: ${userData?.self_referral_code_name || userData?.self_referral_code}
custom Referral Code: [Enter your custom Referral code here]

Thank you,
${userData?.full_name || 'N/A'}`;

    const mailtoLink = `mailto:info@virtualpaysolutions.com?subject=${sub}&body=${encodeURIComponent(emailBody)}`;
    return (
        <div>
            <Box className={classes.container}>
                <Box className={classes.formHeader}>
                    <Typography variant="h4" component="h4">Referral Program</Typography>
                    <Box className={classes.dashboardLink}>
                        <Link to={PATH_OTC.dashboard}>
                            <span><KeyboardBackspaceOutlinedIcon /></span>
                            Back to Dashboard
                        </Link>
                    </Box>
                </Box>
                <Box component="div" className={`${classes.box} ${classes.box1}`}>
                    <Typography variant="h2" component="h2" className={classes.textStyle}>Become our Partner and Refer</Typography>
                    <Grid container spacing={2}>
                        <Grid item className={classes.wrapInput} sm={9}>
                            <TextField size="small" disabled label="" value={copiedUrl} className="bgwhite" fullWidth />
                            {isCopied && <span className={classes.textColour}>Copied Successfully</span>}
                        </Grid>
                        <Grid item sm={3}>
                            <Button className={classes.btnCopy} variant="contained" color="primary" onClick={handleCopy}>
                                <ContentCopyIcon />
                                <Typography variant="h3" component="h3" className={classes.btnText}>
                                    <CopyToClipboard text={`${BASE_URL}/signup/${copiedUrl}`}>
                                        <Typography variant="body2" className={classes.copyButton}>Copy Link</Typography>
                                    </CopyToClipboard>
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" component="p" className={classes.paragraph}>
                        <span className={classes.warningStyle}>
                            To request for a custom referral link, contact our support team.
                        </span>
                        {' '}
                        <Button variant="contained" className={classes.clickBtn}>
                            <Link to={mailtoLink} style={{ textTransform: 'capitalize', color: 'white' }}> Click Here</Link>
                        </Button>
                    </Typography>
                </Box>
                <Box component="div" className={`${classes.box} ${classes.box2}`}>
                    <Typography variant="h2" component="h2">
                        Total Successful referrals
                    </Typography>
                    <Typography variant="body2" component="span">
                        { successfulReferral }
                    </Typography>
                </Box>
                <Box component="div" className={`${classes.box} ${classes.box3}`}>
                    <Stack direction="row" alignItems="center" gap={1} className={classes.heading}>
                        <HistoryIcon />
                        <Typography variant="body1">History of Successful referrals</Typography>
                    </Stack>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date of Refferal</TableCell>
                                    <TableCell>User name</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {referralData && referralData.length > 0 && referralData.map((data: IReferralData) => (
                                    <TableRow>
                                        <TableCell>{new Date(parseInt(data.date_of_referral, 10)).toLocaleString()}</TableCell>
                                        <TableCell>{decryptString(data.user_name)}</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>
    );
}

export default PartnerRefer;

import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from './reducer/loginUser';
import userOnboardingStepsReducer from './reducer/useronboarding';
import userLibraryReducer from './reducer/userLibrary';
import userAuthReducer from './reducer/userAuth';

export const store = configureStore({
    reducer: {
        userData: userDataReducer,
        userOnboardingStep: userOnboardingStepsReducer,
        userAuth: userAuthReducer,
        userLibrary: userLibraryReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

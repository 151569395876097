import { ThemeProvider } from '@mui/material/styles';
import AppRouter from './routers';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './css/global.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FormProvider } from './context/FormContext';
import theme from './theme';
import { GOOGLE_CLIENT_ID } from './helper/constants';
import { store } from './redux/store';
import { Provider } from 'react-redux';

function App() {
    return (
        <Provider store={store}>
            <Toaster />
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <FormProvider>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <AppRouter />
                        </Router>
                    </ThemeProvider>
                </FormProvider>
            </GoogleOAuthProvider>
        </Provider>
    );
}

export default App;

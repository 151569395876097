import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import vpLogo from '../../../assets/vplogo.svg';
import useStyles from '../Auth.style';
import { IS_MOBILE } from '../../../theme/constants';

function ChangePassword() {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6}>
            <Box className={classes.rightMobileSection}>
                <Box className={`${classes.rightHeader} ${classes.rightHeaderChange}`}>
                    <Box className={classes.rightHeadWraper}>
                        <Box className={classes.rightHeaderMobileSection}>
                            <Typography variant="h2">
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <a href="https://virtualpaysolutions.com/" aria-label="Go back">
                                        <ChevronLeftIcon />
                                    </a>
                                    <span>  Change Password</span>
                                </span>
                                <span>
                                    <img src={vpLogo} alt="vpLogo" />
                                </span>
                            </Typography>
                        </Box>
                        <Typography variant="h1">
                            {IS_MOBILE ? 'Authentication' : 'Change Password'}
                        </Typography>
                        <Typography variant="body1">
                            Lorem ipsum dolor sit amet consectetur. Ac commo
                            do diam sit ullamcorper quis.
                        </Typography>
                    </Box>
                </Box>

                <Box className={`${classes.rightBody} ${classes.rightBodyChange}`}>
                    <form>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>Old Password *</InputLabel>
                            <TextField
                                id="outlined-basic-email"
                                type="password"
                                autoComplete="off"
                                placeholder="Old Password"
                                variant="outlined"
                                className={classes.borderStyle}
                            />
                        </Box>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>New Password*</InputLabel>
                            <TextField
                                id="outlined-basic-email"
                                type="password"
                                autoComplete="off"
                                placeholder="New Password"
                                variant="outlined"
                                className={classes.borderStyle}
                            />
                        </Box>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>Confirm Password*</InputLabel>
                            <TextField
                                id="outlined-basic-email"
                                type="password"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                variant="outlined"
                                className={classes.borderStyle}
                            />
                        </Box>
                        <Box className={classes.rightBodyButton}>
                            <button type="submit">Change Password</button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Grid>
    );
}
export default ChangePassword;

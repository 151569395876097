/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, useState } from 'react';
import useDashboardStyles from '../dashboard/Dashboard.style';
import { Box, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchIcon from '@mui/icons-material/Search';
import TrackOrderFormModel from '../../userdetails/components/trackorderformmodel';
import UpdateTransactionStatusModel from '../../userdetails/components/updatetransactionstatusmodel';
import DataGridUserLibrary from '../datagrid/UserLibraryData';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function UserLibrary() {
    const classes = useDashboardStyles();
    const [value, setValue] = React.useState(0);
    const [inputText, setInputText] = useState('');
    const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false);
    const [isUpdateStatusPopupOpen, setIsUpdateStatusPopupOpen] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        // On the change of tab, it sets the inputText value and the new tab value.
        setInputText('');
        setValue(newValue);
    };
    const handleInputText = (e:ChangeEvent<HTMLInputElement>) => {
        // When the input text changes, it will be called and set the InputText.
        setInputText(e.target.value);
    };
    const openDetailPopup = () => {
        // on the click of View More Details this will be called.
        setIsDetailPopupOpen(true);
    };
    const openUpdateStatus = () => {
        // on the click of Update Status this will be called.
        setIsDetailPopupOpen(false);
        setIsUpdateStatusPopupOpen(true);
    };
    return (
        <div className={classes.dashboardWrapper}>
            {isDetailPopupOpen && (
                <TrackOrderFormModel open={isDetailPopupOpen} setOpen={setIsDetailPopupOpen} openUpdateStatus={openUpdateStatus} />
            )}
            {isUpdateStatusPopupOpen && (
                <UpdateTransactionStatusModel open={isUpdateStatusPopupOpen} setOpen={setIsUpdateStatusPopupOpen} />
            )}
            <Box className={classes.tittleInputFilterWrapperStyle}>
                <Box className={classes.tittleInputFilterStyle}>
                    <Typography variant="h2">User Library</Typography>
                    <Box className={classes.searchIconWrapperStyle}>
                        <span className={classes.searchIconnStyle}><SearchIcon /></span>
                        <input type="text" placeholder="Search" value={inputText} onChange={handleInputText} />
                    </Box>
                </Box>
                <Box className={classes.dashboardTabsStyle}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Active Users" {...a11yProps(0)} />
                        <Tab label="Inactive Users" {...a11yProps(1)} />
                    </Tabs>
                </Box>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <DataGridUserLibrary inputText={inputText} openDetailPopup={openDetailPopup} valuee={0} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <DataGridUserLibrary inputText={inputText} openDetailPopup={openDetailPopup} valuee={1} />
            </CustomTabPanel>
        </div>
    );
}

export default UserLibrary;

import { useDispatch } from 'react-redux';
import { setAccessToken } from '../redux/reducer/userAuth';
import { setAccessTokenInLocalStorage, setRefreshTokenInLocalStorage } from '../helper/storageHelper';

export const useHandleLogin = () => {
    const dispatch = useDispatch();

    const handleLogin = (accessToken: string, refreshToken: string) => {
        // Save accessToken in redux store
        dispatch(setAccessToken(accessToken));

        // Encode and store access token in local storage
        setAccessTokenInLocalStorage(accessToken);

        // Store refresh token in local storage
        setRefreshTokenInLocalStorage(refreshToken);
    };

    return handleLogin;
};

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useLoaderStyles = makeStyles((theme:Theme) => ({
    loaderStyle: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vw',
        height: '100vh',
        lineHeight: '100vh',
        zIndex: '9999',
        textAlign: 'center',
        backgroundColor: 'rgb(204, 204, 204, .7)',
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '15px',
        },
    },
}));

export default useLoaderStyles;

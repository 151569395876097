import { RouteObject, useRoutes } from 'react-router-dom';
import ProtectedRoutes from './Protected';
import PublicRoutes from './Public';

export default function Router() {
    const publicRoutes: RouteObject[] = PublicRoutes();
    const protectedRoutes: RouteObject[] = ProtectedRoutes();
    const routes = useRoutes([...publicRoutes, ...protectedRoutes]);
    return routes;
}

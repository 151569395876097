import React, { useEffect } from 'react';
import {
    Box, Typography, Grid, TextField,
} from '@mui/material';
import BusinessInformationIcon from '../../../../assets/business_center.svg';
import { getDecodedItemDataFromLocalStorage, JSONParse } from '../../../../helper/storageHelper';
import AccountStyle from './AccountInformation.style';
import { ISingnUpInfo } from '../../../../interfaces/Auth';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import userQueries from '../../../../queries/formData';
import { useDispatch } from 'react-redux';
import { setDraftData } from '../../../../redux/reducer/useronboarding';
import Loader from '../../../../components/loader';

function AccountInformation() {
    const classes = AccountStyle();
    const userDataString = getDecodedItemDataFromLocalStorage('userData');
    const userData = userDataString ? JSONParse<ISingnUpInfo>(userDataString) : null;
    const firstName: string = userData?.full_name || '';
    const email: string = (userData?.email) as string || '';
    const contact: string = (userData?.contact) as string || '';

    const dispatch = useDispatch();

    const { data: userDraftData, isFetching } = useGQLQuery(
        ['get_draft_data'],
        userQueries.USER_DRAFT_DATA(),
        {},
    );

    useEffect(() => {
        if (userDraftData?.get_draft_data) {
            const address = userDraftData?.get_draft_data?.basicInfoData?.address;
            const userInfo = userDraftData?.get_draft_data?.basicInfoData?.user_info;
            const accountData = userDraftData?.get_draft_data?.accountData || [];

            const draftData = {
                accountData: [{
                    currency: accountData[0]?.currency || '',
                    accountDescription: accountData[0]?.account_description || '',
                    bankingInstruction: accountData[0]?.banking_instructions || '',
                    bankingAddress: accountData[0]?.bank_address || '',
                    institutionNumber: accountData[0]?.institute_number || '',
                    transitNumber: accountData[0]?.transit_number || '',
                    accountNumber: accountData[0]?.account_number || '',
                    swiftBic: accountData[0]?.swift_bic || '',
                    accountHolderFirstName: accountData[0]?.account_holder_first_name || '',
                    accountHolderLastName: accountData[0]?.account_holder_last_name || '',
                    accountHolderEmail: accountData[0]?.account_holder_email || '',
                    beneficiary: accountData[0]?.beneficiary_name || '',
                    beneficiaryAddress: accountData[0]?.beneficiary_address || '',
                    isManuallyCreated: accountData[0]?.is_manually_created || false,
                    accountConfirmation: accountData[0]?.account_confirmation || false,
                    eft_institution: accountData[0]?.eft_institution || '',
                    eft_branch: accountData[0]?.eft_branch || '',
                    bacs_sort_code: accountData[0]?.bacs_sort_code || '',
                    international_bic: accountData[0]?.international_bic || '',
                    international_iban: accountData[0]?.international_iban || '',
                }],
                basicInfoData: {
                    address: {
                        addressLineOne: address?.address_line_1 || '',
                        addressLineTwo: address?.address_line_2 || '',
                        country: address?.country || '',
                        city: address?.city || '',
                        state: address?.state || '',
                        postal_code: address?.postal_code || '',
                    },
                    user_info: {
                        firstName: userInfo?.first_name || '',
                        lastName: userInfo?.last_name || '',
                        contact: userInfo?.contact || userDraftData?.get_draft_data?.googleSSOUserContact || '',
                        email: userInfo?.email || '',
                        settlementCurrency: userInfo?.settlement_currency || '',
                        dob: userInfo?.dob || '',
                        businessType: userInfo?.business_type || '',
                        industry: userInfo?.business_industry || '',
                        businessName: userInfo?.org_name || '',
                        charityName: userInfo?.org_name || '',
                        website: userInfo?.website || '',
                        businessTaxID: userInfo?.tax_id || '',
                        charityTaxID: userInfo?.tax_id || '',
                        representativeContact: userInfo?.representative_contact,
                        representativeContactCountryCode: userInfo?.representative_contact_country_code,
                        representativeEmail: userInfo?.representative_email,
                    },
                },
            };
            dispatch(setDraftData(draftData));
        }
    }, [userDraftData, dispatch]);

    return (
        <Box className={classes.mainSection}>
            { isFetching ? <Loader /> : null }
            <Grid container spacing={4} className={classes.sectionBody}>
                <Grid item xs={12}>
                    <Typography className={classes.subHeaderText} variant="h2">
                        <img src={BusinessInformationIcon} alt={BusinessInformationIcon} />
                        Account Information
                    </Typography>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Typography variant="body1" className={classes.labelText}>
                        Full Name
                    </Typography>
                    <TextField fullWidth color="secondary" value={firstName} disabled />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Typography variant="body1" className={classes.labelText}>
                        Email
                    </Typography>
                    <TextField fullWidth color="secondary" value={email} disabled />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Typography variant="body1" className={classes.labelText}>
                        Phone Number
                    </Typography>
                    <TextField fullWidth color="secondary" value={contact} disabled />
                </Grid>
            </Grid>
        </Box>
    );
}

export default AccountInformation;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const UserDetailsStyle = makeStyles((theme:Theme) => ({
    mainContainer: {
        width: '100%',
        display: 'flex',
    },
    rightSection: {
        marginLeft: '83px',
    },
    firstSubText: {
        color: '#ffffff',
    },
    mainSection: {
        padding: '19px 30px 32px 30px',
        display: 'flex',
        alignItems: 'center',
        gap: '30px',
        '& .sell': {
            backgroundColor: '#ffdada',
            padding: '2px',
            color: '#bd4a4a',
            borderRadius: '10px',
            textAlign: 'center',
            maxWidth: '110px',
        },
        '& .buy': {
            backgroundColor: 'rgba(222, 255, 234, 1)',
            color: 'rgba(30, 127, 65, 1)',
            padding: '2px',
            borderRadius: '10px',
            textAlign: 'center',
            maxWidth: '110px',
        },
        '& .pending-color': {
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: 'rgba(255, 252, 230, 1)',
            color: '#a99323',
            maxWidth: '87px',
            padding: '2px',
        },
        '& .completed-color': {
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: 'rgba(189, 255, 213, 1)',
            color: 'rgba(30, 127, 65, 1)',
            maxWidth: '87px',
            padding: '2px',
        },
        '& .cancelled-color': {
            backgroundColor: 'rgba(255, 189, 189, 1)',
            borderRadius: '10px',
            textAlign: 'center',
            color: '#bd4a4a',
            maxWidth: '87px',
            padding: '2px',
        },
        '& .active-color': {
            backgroundColor: 'rgba(255, 189, 189, 1)',
            borderRadius: '10px',
            textAlign: 'center',
            color: '#bd4a4a',
            maxWidth: '87px',
            padding: '2px',
        },
    },
    mainHeaderText: {
        color: '#000000 !important',
    },
    inBoxText: {
        color: '#054C29 !important',
        backgroundColor: '#BDFFD5',
        borderRadius: '66px',
        padding: '5px 16px',
    },
    inBoxStatus: {
        color: '#CDB400 !important',
        backgroundColor: '#FFFCE6',
        borderRadius: '66px',
        padding: '5px 16px',
    },
    inBoxInactiveStatus: {
        color: '#D80027 !important',
        backgroundColor: '#FFBDBD',
        borderRadius: '66px',
        padding: '5px 16px',
    },
    sectionBody: {
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
            },
        },
        '& input': {
            padding: '10px',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
        '& .MuiSelect-select': {
            padding: '10px',
            fontSize: '14px',
            height: '22px !important',
            borderRadius: '5px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
    },
    subHeaderText: {
        display: 'flex',
        alignItems: 'center',
        color: '#1F1F1F !important',
        fontSize: '18px !important',
        fontFamily: 'Inter-Medium !important',
        marginTop: '32px 20px 16px 32px',
        '& img': {
            marginRight: '4px',
        },
    },
    blueHead: {
        color: '#1A4C8E',
        fontFamily: 'Inter-Regular !important',
        paddingBottom: '4px',
        paddingTop: '4px',
    },
    greySubText: {
        color: '#7E7E7E',
        fontFamily: 'Inter-Regular !important',
    },
    leftBar: {
        width: '83px',
        position: 'fixed',
        height: '100vh',
        backgroundColor: '#0A2A5B',
        overflowX: 'auto',
        '& img': {
            padding: '52px 31px 0px 31px',
        },
    },
    tabBottom: {
        borderBottom: '1px solid #E1E1E1',
        '& .MuiTabs-root': {
            marginLeft: '30px',
            '& button': {
                fontSize: '16px',
                fontFamily: 'Inter-Regular',
                color: '#000000 !important',
                textTransform: 'capitalize',
                letterSpacing: 'inherit',
            },
            '& .Mui-selected': {
                color: '#0D80D4 !important',
                backgroundColor: '#ecf4fd !important',
                fontFamily: 'Inter-Medium !important',
            },
        },
    },
    inTabRight: {
        padding: '25px 30px',
    },
    verifiedBox: {
        padding: '70px',
        borderRadius: '10px',
        boxShadow: '0px 4px 13px 0px rgba(143, 143, 143, 0.25)',
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '59%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '30px',
            left: '65%',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '30px',
            left: '50%',
        },
    },
    termsCheck: {
        display: 'flex',
        alignItems: 'center',
        color: '#1F1F1F',
        '& a': {
            color: '#1F1F1F !important',
        },
    },
    circularProgress: {
        display: 'none',
        '& .MuiCircularProgress-root': {
            width: '75px !important',
            height: '75px !important',
            color: '#7CC54E !important',
            zIndex: '9',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'block',
            padding: '36px 25px 0px 25px',
            '& .MuiBox-root': {
                borderRadius: '50px',
                boxShadow: 'inset 0px 0px 0px 6px #eaeaea',
            },
        },

    },
    cicularStepStyle: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circularBox: {
        position: 'relative',
        display: 'inline-flex',
    },
    navbarTop: {
        position: 'fixed',
        top: '0px',
        zIndex: '999',
        width: '100%',
    },
    mainOverlay: {
        position: 'relative',
        minHeight: '100%',
        color: '#eeeeee',
    },
    overlayLeftBar: {
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100vh',
        width: '83px',
        zIndex: '99',
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        backdropFilter: 'blur(2px)',
        '& img': {
            marginTop: '72px',
            padding: '24px 31px 9px 31px',
            cursor: 'pointer',
        },
    },
    copyButtonStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: '#1A4C8E',
        '& svg': {
            color: '#1A4C8E',
            fontSize: '20px',
            cursor: 'pointer',
        },
        '& span': {
            marginLeft: '15px',
        },
    },
}));

export default UserDetailsStyle;

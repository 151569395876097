import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const tradeOrderCss = makeStyles((theme:Theme) => ({
    formHeader: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '20px 43px',
            textAlign: 'center',
        },
        background: 'linear-gradient(to right,  #0a2a5b 0%,#1968b6 100%)',
        padding: '40px 43px',
        borderRadius: '5px',
        zIndex: '0',
        position: 'relative',
        maxWidth: '764px',
        margin: '0 auto',
        '&  h4': {
            color: '#fff',
            fontSize: '24px',
            fontWeight: '500',
        },
    },
    inputAlign: {
        [theme.breakpoints.between('xs', 'sm')]: {
            marginLeft: '16px',
            marginRight: '16px',
        },
        marginLeft: '92px',
        marginRight: '92px',
    },
    sectionBody: {
        '& .MuiFormControl-root': {
            minWidth: '100%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
            },
        },
        '& .MuiSelect-select': {
            padding: '9.45px 10px',
            fontSize: '14px',
            borderRadius: '10px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
        '& button': {
            lineHeight: '0',
            padding: '20.5px 16px',
            textTransform: 'capitalize',
            fontSize: '16.67px',
            fontWeight: '500',
        },
    },
    enterAmountStyle: {
        alignItems: 'center',
    },
    orStyleCenter: {
        textAlign: 'center',
        color: 'rgba(26, 76, 142, 1)',
    },
    bgwhite: {
        background: '#fff',
        borderRadius: '10px',
    },
    BtnConf: {
        fontSize: '16px',
        fontWeight: '500',
        marginTop: '26px',
        background: '#1968B6',
        textTransform: 'capitalize',
    },
    CheckBoxButton: {
        '& label': {
            [theme.breakpoints.between('xs', 'sm')]: {
                display: 'block',
                width: '100%',
            },
        },
    },
    container: {
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingBottom: '20px',
        },
        maxWidth: '850px',
        margin: '0 auto',
        background: '#eef8ff',
        paddingTop: '44px',
        paddingBottom: '100px',
        '& span': {
            fontSize: '14px',
            color: '#1A4C8E',
            fontWeight: '500',
        },
        '& .checkboxText': {
            display: 'block',
        },
    },
    radio: {
        '& span': {
            color: '#1F1F1F',
        },
        '& :first-child': {
            color: '#C6C6C6',
        },
    },
    xsnone: {
        [theme.breakpoints.between('xs', 'sm')]: {
            // display: 'none',
        },
    },
    radioButton: {
        '& label': {
            [theme.breakpoints.between('xs', 'sm')]: {
                display: 'block',
                width: '100%',
            },
        },
    },
    popupStyle: {
        background: '#fff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '314px',
        height: '263px',
        borderRadius: '13px',
        padding: '25px 30px',
        textAlign: 'center',
        '& img': {
            width: '150px',
            marginTop: '20px',
        },
        '& h2': {
            fontSize: '20px',
            fontWeight: '700',
        },
    },
    smallText: {
        marginTop: '16px',
        display: 'inline-block',
        color: '#8F8F8F',
        fontSize: '12px',
    },
    cross: {
        position: 'absolute',
        right: '30px',
        top: '20px',
        color: '#9E9E9E',
        cursor: 'pointer',
    },
    rightBodyInput: {
        marginBottom: '30px',
        '& p': {
            color: 'rgba(156, 0, 21, 1)',
            marginTop: '7px',
            cursor: 'pointer',
            '& a': {
                color: 'rgba(156, 0, 21, 1)',
            },
        },
        '& input': {
            fontFamily: 'Inter-Medium',
            padding: '10.4px 14px',
            fontSize: '16px',
            color: 'rgba(31, 31, 31, 1)',
        },
        '& label': {
            color: 'rgba(26, 76, 142, 1)',
            marginBottom: '5px',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            borderRadius: '10px',
            background: '#fff',
        },
        '& fieldset': {
            border: '1px solid rgba(198, 198, 198, 1) !important',
        },
    },
    rightBodyInputTokens: {
        marginBottom: '30px',
        '& p': {
            color: 'rgba(156, 0, 21, 1)',
            marginTop: '7px',
            cursor: 'pointer',
            '& a': {
                color: 'rgba(156, 0, 21, 1)',
            },
        },
        '& input': {
            fontFamily: 'Inter-Medium',
            padding: '10.4px 14px',
            fontSize: '14px',
            color: 'rgba(31, 31, 31, 1)',
        },
        '& label': {
            color: 'rgba(26, 76, 142, 1)',
            marginBottom: '5px',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            borderRadius: '10px',
            background: '#fff',
        },
        '& fieldset': {
            border: '1px solid rgba(198, 198, 198, 1) !important',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '20px',
        },
    },
    telephoneBoxStyle: {
        marginBottom: '30px',
        '& input': {
            '&.form-control': {
                borderRadius: '10px',
                lineHeight: 'normal',
                height: 'auto',
                padding: '10.9px 14px 10.9px 48px',
                width: '100%',
                background: '#fff',
                [theme.breakpoints.between('xs', 'sm')]: {
                    fontSize: '16px',
                },
            },
        },
        '& .selected-flag': {
            borderRadius: '10px 0 0 10px',
        },
        '& .flag-dropdown': {
            borderRadius: '10px 0 0 10px',
            backgroundColor: '#fff',
        },
    },
    labelText: {
        margin: '0px 0px 9px 0px !important',
        color: '#1A4C8E',
    },
    errorStyle: {
        display: 'block',
        color: '#d32f2f !important',
        marginLeft: '14px',
        marginTop: '7px',
        fontSize: '0.75rem !important',
    },
    buyCryptoStyle: {
        width: '100%',
    },
    warningStyle: {
        color: 'rgba(179, 129, 0, 1) !important',
        fontSize: '12px !important',
        marginTop: '7px',
        display: 'block',
        '& svg': {
            fontSize: '18px',
            verticalAlign: 'middle',
            marginRight: '8px',
        },
    },
    rightBodyInputCheckbox: {
        '& fieldset': {
            border: 'none !important',
        },
        '& label': {
            marginBottom: '0px',
        },
    },
    dashboardLink: {
        marginTop: '7px',
        '& a': {
            color: '#eee',
            textDecoration: 'underline',
            fontSize: '14px',
            transition: '.6s',
            '&:hover': {
                fontSize: '15px',
                '& svg': {
                    fontSize: '20px',
                },
            },
        },
        '& svg': {
            verticalAlign: 'middle',
            fontSize: '18px',
            transition: '.6s',
        },
    },
    scannerWrapper: {
        width: '300px',
        height: '300px',
        margin: '0px auto',
        textAlign: 'center',
        '& button': {
            fontSize: '12px',
            textTransform: 'capitalize',
            marginTop: '35px',
            padding: '2.5px 10px',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#1976d2',
            },
        },
    },
}));

export default tradeOrderCss;

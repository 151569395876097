// Environment file constants
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';
const BASE_URL = process.env.REACT_APP_BASE_URL || '';
const AES_KEY = process.env.REACT_APP_AES_KEY || '';

// GraphQL endpoint
const GRAPHQL = '/api/graphql';

//  CSV Export endpoint
const CSV_ENDPOINT = '/csvdownload';

// Google api key
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// React DataGrid key
const REACT_DATAGRID_KEY = process.env.REACT_APP_DATAGRID_KEY;

// Google Address API restricted conf
const RESTRICTED_STATES = {
    USA: ['NY', 'AK', 'FL', 'LA'],
};

// API request method configuration
const METHOD_CONF = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'DELETE',
    PATCH: 'patch',
};

// Currecncy Key
const CURRENCY = {
    USD: 'USD',
};

// URL config
const URL_CONF = {
    LOGOUT: '/logout',
    DEFAULT: '/list', // Default URL
    AUTHENTICATION: '/authentication',
    ADMIN: '/admin',
};

const CONTACT_SUPPORT = {
    VISA_PAY_CONTACT: 'info@virtualpaysolutions.com',
};

// GraphQL key config
const KEY_CONF = {
    REGISTER: 'register',
    USERS: 'users',
    FORGOT_PASSWORD: 'forget_password',
    AUTHENTICATION: 'authentication',
    AUTHENTICATION_GOOGLE_LOGIN: 'authenticationGoogleLogin',
    AUTHENTICATION_GOOGLE_SIGNUP: 'authenticationGoogleSignup',
    REFRESH_TOKEN: 'refreshToken',
    PLAID_VERIFY: 'plaidVerify',
    TOS: 'tos',
    REFERRAL_USER_LIST: 'referral_user_list',
    // OTC Dashboard
    USER_TRANSACTIONS: 'userTransactions',
    TRADE_BUY: 'tradeBuy',
    TRADE_SELL: 'tradeSell',
    GET_DRAFT_DATA: 'get_draft_data',
    SAVE_DRFT_DATA: 'save_draft',
    SAVE_ON_NEXT_DRAFT: 'save_on_next_draft',
    ACCOUNT: 'get_account',
    REFERRAL_PROGRAM: 'referral_program',
    CHANGE_PASSWORD: 'change_password',
    CURRENCY_LIST: 'currency_list',
    // Admin Dashboard
    ADMIN_DASHBOARD: 'adminDashboard',
    ADMIN_TRANDE_ORDER: 'adminTrandeOrder',
    USER_LIBRARY_ACTIVE: 'userLibraryActive',
    USER_LIBRARY_INACTIVE: 'userLibraryInactive',
    USER_LIBRARY_DETAIL: 'userLibraryDetail',
    USER_LIBRARY_TRANSACTION: 'userLibraryTransaction',
    USER_LIBRARY_REFERRAL: 'userLibraryReferral',
    USER_UPDATE_STATUS: 'userUpdateStatus',
    ADMIN_USER_DATA: 'admin_dashboard',
    SINGLE_USER_DETAIL: 'singleUserDetail',
    ADMIN_CHANGE_USER_STATUS: 'adminchangestatus',
    ADMIN_TRANSACTION: 'admintransaction',
    ADMIN_CARD_DATA: 'admin_card_data',
    USER_LIBRARY_DATA: 'userLibrary',
    ADD_CUSTOMER_WALLET: 'add_customer_wallet',
    CUSTOMER_PAYOUT: 'customer_payout',
    AMOUNT_TRANSACTED: 'amount_transacted',
    UPDATE_REFERRAL_CODE: 'update_referral_code_name',
};

const RESULT_PER_PAGE = 10; // for showing OTC Transaction 10 Data per page
const DEFAULT_PAGE_SIZE = 20; // Default page size
const SUPER_ADMIN_ROLE = 2; // Super admin role ID
const ADMIN_ROLE = 1; // Admin role ID
const USER_ROLE = 0; // User role ID

const ROLES = [
    { id: SUPER_ADMIN_ROLE, role: 'Super Admin' }, // Super admin role
    { id: ADMIN_ROLE, role: 'Admin' }, // Admin role
    { id: USER_ROLE, role: 'User' }, // User role
];

// Navbar menu options
const NAVBAR_MENU = {
    DASHBOARD: 'dashboard',
    ACCOUNT: 'account',
    LOGOUT: 'logout',
    REFERRAL: 'referral',
    CHANGE_PASSWORD: 'change-password',
};

// Error messages
const ERRORS = {
    EMAIL_ERROR: 'Please enter a valid email address.',
    PASSWORD_ERROR: `Password must contain 8-20 characters, including at least one uppercase letter,
    one lowercase letter, one number, and one special character.`,
    USERNAME_ERROR: 'Please enter a valid User Name.',
    PHONE_ERROR: 'Please enter the valid Phone Number',
    PASSWORD_OLD: 'Please enter the Old Password.',
    PASSWORD_NEW: 'Please enter the valid New Password.',
    PASSWORD_CONFIRM: 'Please enter the Confirm Password',
    PASSWORD_CONFIRM2: 'Please enter the Confirm password similar to New Password',
    OLD_NEW: 'Please enter the password different from Old Password',
    // tradeOrder
    WHAT_TODAY: 'Please select what you want to do today?',
    SELECT_CRYPTO: 'Please select the crypto',
    WALLET_ADDRESS: 'Please write the valid Wallet address',
    AMOUNT_TOKEN: 'Please enter the valid amount or quantity',
    QTY_TOKEN: 'Please enter the valid amount',
    CONTACT_METHOD: 'Please enter the valid contact method',
    EMAIL_COPY_ERROR: 'Unable to copied email',
    REFERRAL_CODE_ERROR: 'Special characters not allowed. Please enter the valid Referral code.',
};

// Success messages
const MESSAGES = {
    USER_LOGIN: 'User successfully logged in!',
    BANK_ACCOUNT_SAVE_SUCCESS: 'Bank account saved successfully.',
    SUCCESS_CHANGE_PASSWORD: 'You have Successfully Changed Your Password!',
    STATE_RESTRICTION_MESSAGE: 'We do not operate in the selected state',
    EMAIL_COPY: 'Email Copied!',
};

// API error messages
const API_ERRORS = {
    LOGIN_FAILED: 'Login with Google failed!',
    REGISTRATION_FAILED: 'Registration with google failed!',
};

// Plaid messages
const PLAID_MESSAGE = {
    LINK_TOKEN_ERROR: 'Error fetching link token',
    ALREADY_PLAID_VERIFIED: 'Your account is already Plaid verified',
};

const STATUS_COLOR = {
    BUY: 'Buy',
    SELL: 'Sell',
    CANCELLED: 'CANCELLED',
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
};

const TRADE_ORDER_DATE = {
    PENDING_COMPLETION: 'Order Completion Date',
    CANCELLATION: 'Order Cancellation Date',
};

const COLOR_DATA = {
    BUY: 'buy',
    SELL: 'sell',
    CANCEL: 'cancelled-color',
    PENDING: 'pending-color',
    COMPLETE: 'completed-color',
};
const HANDLE_TRANSACTION = {
    CANCEL: 'Cancelled',
    PENDING: 'Pending',
    COMPLETE: 'Completed',
};

// Account types
export enum AccountType {
    Personal = 1,
    Business = 2,
    Charity = 3,
  }

// User types
const USER_TYPES = [
    { id: AccountType.Personal, type: 'Personal' },
    { id: AccountType.Business, type: 'Business' },
    { id: AccountType.Charity, type: 'Charity' },
];

// User on-boarding steps
const PERSONAL_INFORMATION = 'PERSONAL_INFORMATION';
const PERSONAL_INFORMATION_VALIDATION = 'PERSONAL_INFORMATION_VALIDATION';
const ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION';
const ACCOUNT_VERIFICATION_COMPLETED = 'ACCOUNT_VERIFICATION_COMPLETED';
const ACCOUNT_INFO = 'ACCOUNT_INFO';
const ACCOUNT_INFO_VALIDATION = 'ACCOUNT_INFO_VALIDATION';
const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS';
const TERMS_AND_CONDITIONS_ACCEPTED = 'TERMS_AND_CONDITIONS_ACCEPTED';
const PROCEED_ONBOARDING_CONFIRMATION = 'PROCEED_ONBOARDING_CONFIRMATION';
const PROCEED_KYC_CONFIRMATION = 'PROCEED_KYC_CONFIRMATION';
const PROCEED_ACCOUNT_INFO_CONFIRMATION = 'PROCEED_ACCOUNT_INFO_CONFIRMATION';

const USER_ONBOARDING_STEPS = [
    PERSONAL_INFORMATION,
    PERSONAL_INFORMATION_VALIDATION,
    // PROCEED_ACCOUNT_INFO_CONFIRMATION,
    // ACCOUNT_VERIFICATION_COMPLETED,
    ACCOUNT_INFO,
    ACCOUNT_INFO_VALIDATION,
    PROCEED_KYC_CONFIRMATION,
    // ACCOUNT_VERIFICATION,
    // PROCEED_ONBOARDING_CONFIRMATION,
    TERMS_AND_CONDITIONS,
    // TERMS_AND_CONDITIONS_ACCEPTED,
];

// User pending stages
const USER_VERIFIED_STATUS = 'verified';
const USER_INFO_STATUS = 'user_info';
const USER_KYC_STATUS = 'user_kyc_info';
const USER_ACCOUNT_STATUS = 'user_account_info';
const TERMS_AND_CONDITIONS_ACCEPT = 'terms_and_conditions';

// Supported countries for the application
const COUNTRIES_SUPPORTED = ['USA', 'CAN'];

// totalSteps for mobile circular progress
export const TOTAL_STEPS = 4;
export const PROGRESS_STEPS = 25;

const DEFAULT_COUNTRY = {
    NAME: 'United States',
    CODE: 'USA',
};

// Pagination
const PAGINATION = {
    FIRST: 'first',
    LAST: 'last',
    PREVIOUS: 'previous',
    NEXT: 'next',
};

// Routing
const ROUTING_ADMIN_PAGE = {
    LIBRARY: 'user-library',
};

// Industry Name
const INDUSTRY_LISTED = [
    { id: '1', label: 'Travel', value: 'travel' },
    { id: '2', label: 'Education', value: 'education' },
    { id: '3', label: 'Healthcare', value: 'healthcare' },
    { id: '4', label: 'Real Estate', value: 'realEstate' },
    { id: '5', label: 'Retail and e-Commerce', value: 'retailAndEcommerce' },
    { id: '6', label: 'Events Management', value: 'eventsManagement' },
    { id: '7', label: 'Finance and Banking', value: 'financeAndBanking' },
    { id: '8', label: 'Non-Profit', value: 'nonProfit' },
    { id: '9', label: 'Government', value: 'government' },
    { id: '10', label: 'Manufacturing', value: 'manufacturing' },
    { id: '11', label: 'Technology', value: 'technology' },
    { id: '12', label: 'Food and Beverage', value: 'foodAndBeverage' },
    { id: '13', label: 'Construction', value: 'construction' },
    { id: '14', label: 'Other', value: 'other' },
];

// Type of bussiness name
const BUSSINESS_TYPELIST = [
    { id: '1', label: 'Sole Proprietorship', value: 'soleProprietorship' },
    { id: '2', label: 'Partnership', value: 'partnership' },
    { id: '3', label: 'Corporation', value: 'corporation' },
    { id: '4', label: 'Cooperative (for profit & not for profit)', value: 'cooperative' },
    { id: '5', label: 'Other', value: 'other' },
];

// Unauthorized status code
export const UNAUTHORIZED = { code: 401, message: 'Unauthorized' };

// Spherepay customer status
export const CUSTOMER_CREATED = 'CUSTOMER_CREATED';
export const KYC_PENDING = 'KYC_PENDING';
export const KYC_VERIFIED = 'KYC_VERIFIED';
export const TERMS_OF_SERVICE_ACCEPTED = 'TERMS_OF_SERVICE_ACCEPTED';
export const BANK_ACCOUNT_CREATED = 'BANK_ACCOUNT_CREATED';
export const WALLET_CREATED = 'WALLET_CREATED';

// Referral email
export const SUBJECT = 'Request for Custom Referral Link';

// Exporting constants, enums, and objects
export {
    TRADE_ORDER_DATE,
    DEFAULT_PAGE_SIZE,
    RESULT_PER_PAGE,
    SUPER_ADMIN_ROLE,
    ADMIN_ROLE,
    USER_ROLE,
    ROLES,
    ERRORS,
    USER_TYPES,
    MESSAGES,
    API_ERRORS,
    GOOGLE_CLIENT_ID,
    SERVER_URL,
    BASE_URL,
    AES_KEY,
    GRAPHQL,
    URL_CONF,
    KEY_CONF,
    NAVBAR_MENU,
    USER_ONBOARDING_STEPS,
    PERSONAL_INFORMATION,
    PROCEED_ACCOUNT_INFO_CONFIRMATION,
    PERSONAL_INFORMATION_VALIDATION,
    PROCEED_KYC_CONFIRMATION,
    ACCOUNT_VERIFICATION,
    ACCOUNT_VERIFICATION_COMPLETED,
    ACCOUNT_INFO,
    ACCOUNT_INFO_VALIDATION,
    TERMS_AND_CONDITIONS,
    GOOGLE_API_KEY,
    USER_VERIFIED_STATUS,
    USER_INFO_STATUS,
    USER_KYC_STATUS,
    USER_ACCOUNT_STATUS,
    TERMS_AND_CONDITIONS_ACCEPT,
    REACT_DATAGRID_KEY,
    TERMS_AND_CONDITIONS_ACCEPTED,
    METHOD_CONF,
    COUNTRIES_SUPPORTED,
    STATUS_COLOR,
    COLOR_DATA,
    HANDLE_TRANSACTION,
    CURRENCY,
    PAGINATION,
    PROCEED_ONBOARDING_CONFIRMATION,
    RESTRICTED_STATES,
    ROUTING_ADMIN_PAGE,
    CSV_ENDPOINT,
    CONTACT_SUPPORT,
    INDUSTRY_LISTED,
    BUSSINESS_TYPELIST,
    DEFAULT_COUNTRY,
    PLAID_MESSAGE,
};

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const MobileHomePage = makeStyles((theme:Theme) => ({
    rightMobileSection: {
        display: 'none',
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'block',
            padding: '0px',
        },
    },
    bottomSetion: {
        color: 'rgba(0, 0, 0, 1)',
        position: 'absolute',
        bottom: '5%',
        '& h2': {
            cursor: 'pointer',
        },
        '& span': {
            verticalAlign: 'text-top',
            marginRight: '10px',
        },
    },
    rightBody: {
        [theme.breakpoints.between('md', 'lg')]: {
            padding: '30px 0px 0px 0px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '20px 0px 0px 0px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '72px 30px 50px 30px',
        },
    },
    rightHeader: {
        [theme.breakpoints.between('lg', 'xl')]: {
            height: '100px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            height: '40px',
            paddingBottom: '15px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: '25px',
            paddingBottom: '8px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            height: 'auto',
            paddingBottom: '15px',
            backgroundColor: '#fff',
            display: 'block',
            position: 'fixed',
            width: '100%',
            zIndex: 999,
            top: 0,
        },
        '& h1': {
            color: 'rgba(31, 31, 31, 1)',
            fontSize: '25px',
            textAlign: 'center',
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: '16px',
            },
        },
        '& p': {
            color: 'rgba(153, 158, 161, 1)',
            marginTop: '12px',
            textAlign: 'center',
            [theme.breakpoints.between('xs', 'sm')]: {
                textAlign: 'left',
                color: 'rgba(153, 158, 161, 1)',
            },
        },
    },
    rightHeaderMobileSection: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '18px 15px 18px 30px',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    mobileHeaderText: {
        fontSize: '32px !important',
        marginTop: '18% !important',
        letterSpacing: '0.02em !important',
        lineHeight: '48px !important',
    },
    mobileHeaderTextSub: {
        marginTop: '4% !important',
        letterSpacing: '0.02em !important',
        lineHeight: '33px !important',
        color: 'rgba(95, 95, 95, 1)',
    },
    rightBodyButtonDark: {
        marginTop: '10% !important',
        '& button': {
            color: '#fff',
            backgroundColor: 'rgba(10, 42, 91, 1)',
            borderRadius: '50px',
            cursor: 'pointer',
            textAlign: 'center',
            padding: '12px 0px',
            border: 'none',
            width: '220px',
            fontSize: 21,
            fontFamily: 'Inter-Medium',
        },
    },
    rightBodyButtonSm: {
        '& button': {
            color: '#fff',
            backgroundColor: 'rgba(10, 42, 91, 1)',
            borderRadius: '50px',
            cursor: 'pointer',
            textAlign: 'center',
            padding: '12px 0px',
            border: 'none',
            width: '85px',
            fontSize: 10,
            fontFamily: 'Inter-Medium',
        },
    },
}));

export default MobileHomePage;

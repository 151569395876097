import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import {
    Box,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import React, {
    ChangeEvent, FormEvent, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { KEY_CONF } from '../../../helper/constants';
import { ERRORS } from '../../../helper/validationErrors';
import { emailValidator, numberValidator } from '../../../helper/validators';
import { useGQLMutation } from '../../../hooks/useGQLMutation';
import { ITraderOrder } from '../../../interfaces/User';
import { ADD_CUSTOMER_WALLET } from '../../../queries/wallets';
import { PATH_OTC } from '../../../routers/path';
import { MutationResponseType, QueryErrorResponse } from '../../../types/UserDetailType';
import ModalSubmit from './ModalSubmit';
import TradeForm from './TradeForm';
import tradeOrderCss from './TradeOrder.style';
import { CUSTOMER_PAYOUT } from '../../../queries/payout';
import Loader from '../../../components/loader';
import PayoutSuccessPopup from '../popup';
import toast from 'react-hot-toast';

function Tradeorder() {
    const classes = tradeOrderCss();
    const [open, setOpen] = React.useState(false);
    const [walletCreationError, setWalletCreationError] = useState<string>('');
    const [tradeState, setTradeState] = useState<ITraderOrder>({
        tradeAction: 'buy',
        cryptoType: '',
        walletAddress: '',
        tradeAmount: '',
        tradeToken: '',
        phoneNumber: '',
        email: '',
        blockchain: '',
        contactMethod: '',
    });
    const [errorState, setErrorState] = useState<ITraderOrder>({
        tradeAction: '',
        cryptoType: '',
        walletAddress: '',
        tradeAmount: '',
        tradeToken: '',
        phoneNumber: '',
        email: '',
        contactMethod: '',
        blockchain: '',
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
    const [payoutID, setPayoutID] = useState<string>('');

    const validateFields = () => {
        // it is being on the submission of the form;
        let transactionEmailError = '';
        let tradeAmountError = '';
        let tradeTokenError = '';

        // Email validations
        if (tradeState.email && !emailValidator(tradeState.email)) {
            transactionEmailError = ERRORS.EMAIL_ERROR;
        } else if ((tradeState.email).trim().length === 0) {
            transactionEmailError = ERRORS.MANDATORY_FIELD;
        }

        // Numeric validation for trade amount
        if (tradeState.tradeAmount && numberValidator(+tradeState.tradeAmount)) {
            tradeAmountError = ERRORS.NUMERIC_FIELD_ERROR;
        } else if ((tradeState.tradeToken).trim().length === 0 && (tradeState.tradeAmount).trim().length === 0) {
            tradeAmountError = ERRORS.MANDATORY_FIELD;
        } else if (Number(tradeState.tradeAmount) > 20000000) {
            tradeAmountError = ERRORS.LIMIT_EXCEED_ERROR;
        }

        // Numeric validation for trade quantity of tokens
        if (tradeState.tradeToken && numberValidator(+tradeState.tradeToken)) {
            tradeTokenError = ERRORS.NUMERIC_FIELD_ERROR;
        } else if ((tradeState.tradeToken).trim().length === 0 && (tradeState.tradeAmount).trim().length === 0) {
            tradeTokenError = ERRORS.MANDATORY_FIELD;
        } else if (Number(tradeState.tradeToken) > 20000000) {
            tradeTokenError = ERRORS.LIMIT_EXCEED_ERROR;
        }

        setErrorState({
            tradeAction: !tradeState.tradeAction ? ERRORS.MANDATORY_FIELD : '',
            cryptoType: !tradeState?.cryptoType ? ERRORS.MANDATORY_FIELD : '',
            blockchain: !tradeState?.blockchain ? ERRORS.MANDATORY_FIELD : '',
            walletAddress: !tradeState.walletAddress ? ERRORS.MANDATORY_FIELD : '',
            phoneNumber: !tradeState.phoneNumber ? ERRORS.MANDATORY_FIELD : '',
            email: transactionEmailError,
            contactMethod: !tradeState.contactMethod ? ERRORS.MANDATORY_FIELD : '',
            tradeAmount: tradeAmountError,
            tradeToken: tradeTokenError,
        });

        if (transactionEmailError || tradeAmountError || tradeTokenError) {
            return false;
        }
        // Mandatory check for all fields
        if (!tradeState.tradeAction || !tradeState.cryptoType || !tradeState.blockchain
            || (!tradeState.tradeAmount && !tradeState.tradeToken) || !tradeState.walletAddress
            || !tradeState.phoneNumber || !tradeState.email || !tradeState.contactMethod) {
            return false;
        }

        return true;
    };

    const handleTextfieldChange = (event: SelectChangeEvent) => {
        // on the change of textField it is being called and update the state.
        const { name, value } = event.target;
        setTradeState({
            ...tradeState,
            [name]: value,
        });
        // Clear error state
        if (name === 'tradeAmount' || name === 'tradeToken') {
            setErrorState({
                ...errorState,
                tradeAmount: '',
                tradeToken: '',
            });
        } else {
            setErrorState({
                ...errorState,
                [name]: '',
            });
        }
    };

    const handleListItemChange = (event: SelectChangeEvent) => {
        // it is being called after selecting from the select dropdown.
        const { name, value } = event.target as HTMLInputElement;
        setTradeState({
            ...tradeState,
            [name]: value,
        });
        // Clear error state
        setErrorState({
            ...errorState,
            [name]: '',
        });
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        // it is being called checked value is updated.
        const { name, checked } = event.target;
        setTradeState({
            ...tradeState,
            contactMethod: checked ? name : '',
        });
        // Clear error state
        setErrorState({
            ...errorState,
            contactMethod: '',
        });
    };

    // called to close the modal
    const handleClose = () => setOpen(false);

    const handleContactChange = (value: string) => {
        // on the change of the contact value it is being called.
        setTradeState({
            ...tradeState,
            phoneNumber: value !== null ? value : '',
        });
        // Clear error state
        setErrorState({
            ...errorState,
            phoneNumber: '',
        });
    };
    const { mutate: customerPayout } = useGQLMutation(
        KEY_CONF.CUSTOMER_PAYOUT,
        CUSTOMER_PAYOUT(),
        {
            // Show a success message when the payout mutation succeeds
            onSuccess(res: MutationResponseType) {
                if (res?.customer_payout?.success) {
                    if (res?.customer_payout?.payout_id) {
                        setPayoutID(res?.customer_payout?.payout_id);
                    }
                    setLoading(false);
                    setShowSuccessPopup(true);
                    setTradeState({
                        tradeAction: 'buy',
                        cryptoType: '',
                        walletAddress: '',
                        tradeAmount: '',
                        tradeToken: '',
                        phoneNumber: '',
                        email: '',
                        blockchain: '',
                        contactMethod: '',
                    });
                }
            },
            onError: ({ response }: QueryErrorResponse) => {
                const errorMessage = response.errors?.[0]?.message || 'Failed while making Payout. Please try again.';
                if (errorMessage) {
                    toast.error(errorMessage);
                }
                setLoading(false);
            },
            enabled: false,
        },
    );
    const { mutate: addWallet } = useGQLMutation(
        KEY_CONF.ADD_CUSTOMER_WALLET,
        ADD_CUSTOMER_WALLET(),
        {
            // If the wallet addition is successful, trigger the payout request
            onSuccess: (res: MutationResponseType) => {
                if (res?.add_customer_wallet.success) {
                    // Make the payout request with necessary details
                    customerPayout({
                        amount: parseFloat(tradeState.tradeAmount || tradeState.tradeToken),
                        token: parseFloat(tradeState.tradeToken || tradeState.tradeAmount),
                        crypto_type: tradeState.cryptoType,
                        blockchain: tradeState.blockchain === 'solana' ? 'sol' : tradeState.blockchain,
                        transaction_type: tradeState.tradeAction,
                        wallet_address: tradeState.walletAddress,
                        mode_of_contact: tradeState.contactMethod,
                    });
                }
            },
            onError: ({ response }: QueryErrorResponse) => {
                const errorMessage = response.errors?.[0]?.message || 'Failed to add wallet. Please try again.';
                setWalletCreationError(errorMessage);
                setLoading(false);
            },
            enabled: false,
        },
    );

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Perform field validation
        if (!validateFields()) {
            return;
        }
        setLoading(true);
        // Trigger the addWallet mutation with the blockchain network and wallet address
        addWallet({
            network: tradeState.blockchain,
            address: tradeState.walletAddress,
        });
    };

    const closePopupHandler = () => {
        setShowSuccessPopup(false);
    };

    return (
        <>
            {showSuccessPopup && <PayoutSuccessPopup id={payoutID} open={showSuccessPopup} handleClose={closePopupHandler} />}
            <div>
                {loading && <Loader />}
                <Box className={classes.formHeader}>
                    <Typography variant="h4" component="h4">Trade Order Form</Typography>
                    <Box className={classes.dashboardLink}>
                        <Link to={PATH_OTC.dashboard}>
                            <span><KeyboardBackspaceOutlinedIcon /></span>
                            Back to Dashboard
                        </Link>
                    </Box>
                </Box>
                <Box className={classes.container}>
                    <Box component="div" className={classes.inputAlign}>
                        <TradeForm
                            tradeState={tradeState}
                            errorState={errorState}
                            handleTextfieldChange={handleTextfieldChange}
                            handleListItemChange={handleListItemChange}
                            handleContactChange={handleContactChange}
                            handleSubmit={handleSubmit}
                            handleCheckboxChange={handleCheckboxChange}
                            walletCreationError={walletCreationError}
                        />
                    </Box>
                </Box>
                <ModalSubmit
                    open={open}
                    handleClose={handleClose}
                />
            </div>
        </>
    );
}

export default Tradeorder;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const BusinessStyle = makeStyles((theme:Theme) => ({
    mainSection: {
        padding: '48px 48px 94px 48px !important',
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '48px 25px 94px 25px !important',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '10px 15px 94px 15px !important',
        },
        '& .MuiOutlinedInput-root': {
            background: '#F5F5F5',
            '& .MuiSelect-select': {
                background: '#F5F5F5',
            },
        },
    },
    mainHeaderText: {
        color: '#0A2A5B !important',
        fontSize: '23px',
        fontFamily: 'Inter-Regular !important',
        textAlign: 'center',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '18px',
            fontFamily: 'Inter-Medium !important',
        },
    },
    mainSubMobile: {
        display: 'none',
        [theme.breakpoints.between('xs', 'sm')]: {
            color: '#464646 !important',
            fontFamily: 'Inter-Medium !important',
            display: 'block',
        },
    },
    subHeaderText: {
        display: 'flex',
        alignItems: 'center',
        color: '#1F1F1F !important',
        fontSize: '18px',
        fontFamily: 'Inter-Medium !important',
        '& img': {
            marginRight: '6px',
        },
    },
    sectionBody: {
        marginTop: '0px !important',
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
            },
        },
        '& input': {
            padding: '10.45px 10px',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
        '& .MuiSelect-select': {
            padding: '8.5px 10px',
            fontSize: '14px',
            height: '22px !important',
            borderRadius: '5px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
        '& .MuiGrid-item': {
            paddingTop: '0px !important',
            paddingBottom: '0px',
        },
        '& .MuiGrid-item:first-child': {
            [theme.breakpoints.between('xs', 'sm')]: {
                paddingBottom: '0px',
            },
            paddingBottom: '8px',
        },
    },
    mt40: {
        display: 'flex',
        marginTop: '40px !important',
        alignItems: 'center',
        marginBottom: '0px !important',
        '& img': {
            marginRight: '6px',
        },
    },
    labelText: {
        margin: '24px 0px 9px 0px !important',
        color: '#1A4C8E',
        fontWeight: '500',
    },
    rightBodyInput: {
        '& p': {
            textAlign: 'right',
            color: 'rgba(156, 0, 21, 1)',
            marginTop: '7px',
            cursor: 'pointer',
            '& a': {
                color: 'rgba(156, 0, 21, 1)',
            },
        },
        '& input': {
            fontFamily: 'Inter-Medium',
            padding: '10.4px 14px',
            fontSize: '16px',
            color: 'rgba(31, 31, 31, 1)',
        },
        '& label': {
            color: 'rgba(26, 76, 142, 1)',
            marginBottom: '5px',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            borderRadius: '10px',
        },
        '& fieldset': {
            border: '1px solid rgba(198, 198, 198, 1) !important',
        },
    },
    telephoneBoxStyle: {
        '& input': {
            '&.form-control': {
                borderRadius: '10px',
                lineHeight: 'normal',
                height: 'auto',
                padding: '10.9px 14px 10.9px 48px',
                width: '100%',
                background: '#f5f5f5',
                [theme.breakpoints.between('xs', 'sm')]: {
                    fontSize: '16px',
                },
            },
        },
        '& .selected-flag': {
            borderRadius: '10px 0 0 10px',
        },
        '& .flag-dropdown': {
            borderRadius: '10px 0 0 10px',
            backgroundColor: '#f5f5f5',
        },
    },

}));

export default BusinessStyle;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const NavbarStyle = makeStyles((theme:Theme) => ({
    navbar: {
        background: '#fff',
        color: '#fff',
        padding: '0px 0px 0px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 4px 4px 0px #BABABA40',
        zIndex: '1',
        position: 'relative',
        '& .profile': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            color: '#000',
            '& .profileName': {
                [theme.breakpoints.between('xs', 'sm')]: {
                    display: 'none',
                },
                fontSize: '14px',
                padding: '0px 5px',
                color: '#252525',
                fontWeight: '600',
            },
            '& .downImg': {
                [theme.breakpoints.between('xs', 'sm')]: {
                    display: 'none',
                },
            },
            '& .downarrow': {
                position: 'relative',
                borderRadius: '2px',
                transition: '.5s',
                display: 'flex',
                height: '70px',
                padding: '0px 20px',
                backgroundColor: 'rgb(165 144 144 / 14%)',
                '&:hover': {
                    backgroundColor: '#1769B6',
                    '& svg': {
                        color: '#fff',
                    },
                    '& .profileName': {
                        color: '#fff',
                    },
                },
                '& svg': {
                    fontSize: '21px',
                    verticalAlign: 'text-bottom',
                    color: '#252525',
                },
            },
            '& .dropDownColor': {
                position: 'relative',
                borderRadius: '2px',
                transition: '.5s',
                display: 'flex',
                height: '70px',
                padding: '0px 20px',
                backgroundColor: '#1769B6',
                '& svg': {
                    color: '#fff',
                    fontSize: '21px',
                    verticalAlign: 'text-bottom',
                },
                '& .profileName': {
                    color: '#fff',
                },
            },
        },
        '& .dropdown': {
            position: 'relative',
            display: 'inline-block',
            ml: 2,
        },
        '& .dropdown-content': {
            top: '50px',
            right: '15px',
            display: 'block',
            position: 'absolute',
            background: '#fff',
            minWidth: '75px',
            boxShadow: '0px 4px 4px 0px #BABABA40',
            zIndex: 1,
            padding: '10px 15px',
            fontSize: '14px',
            borderRadius: '10px',
        },
        '& li': {
            listStyle: 'none',
            padding: '5px',
            '& :hover': {
                Background: '#ddd',
            },
            '& a': {
                textDecoration: 'none',
                color: '#000',
            },
        },
        '& .profile:hover .dropdown-content': {
            display: 'block',
        },
        '& .menubox': {
            overflow: 'visible',
            mt: 1,
            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: '#ddd',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 1,
            },
        },
    },
    navbarTop: {
        position: 'sticky',
        top: '0px',
        zIndex: '999',
        width: '100%',
    },
    dropDownMenu: {
        '& .MuiBackdrop-invisible': {
            [theme.breakpoints.between('xs', 'sm')]: {
                background: '#33333369',
            },
        },
        '& .MuiPaper-root': {
            border: '1px solid rgba(233, 233, 233, 1) !important',
            borderRadius: '29px, 29px, 0px, 0px !important',
            boxShadow: 'none !important',
            [theme.breakpoints.between('xs', 'sm')]: {
                maxWidth: '100% !important',
                left: '0px !important',
                right: 0,
                bottom: 0,
                top: 'auto !important',
                boxShadow: '0px 2px 6px 0px #00000029',
                borderTopRightRadius: '20px',
                borderTopLeftRadius: '20px',
                overflow: 'visible',
            },
        },
        '& .closeBtn': {
            display: 'none',
            [theme.breakpoints.between('xs', 'sm')]: {
                display: 'block',
                position: 'absolute',
                top: '-7%',
                right: '2%',
                background: '#fff',
                padding: '8px 8px 6px',
                borderRadius: '50%',
                boxShadow: '#ccc 1px 0px 10px',
            },
        },
        '& ul': {
            [theme.breakpoints.between('xs', 'sm')]: {
                width: '100% !important',
            },
            width: '233px !important',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
            '& li': {
                padding: '10px 18px',
                '&:nth-child(5)': {
                    borderBottom: 'none !important',
                },
            },
        },
    },
    navbarDashboardStyle: {
        marginRight: '35px !important',
    },
    homeIconStyle: {
        '& svg': {
            color: '#1769B6',
            fontSize: '25px',
            verticalAlign: 'top',
            cursor: 'pointer',
            marginLeft: '20px',
        },
    },
}));

export default NavbarStyle;

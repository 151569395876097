import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const PaginationStyle = makeStyles((theme:Theme) => ({
    paginationStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        width: '100%',
        fontSize: '12px',
        borderTop: '1px solid #C6C6C6',
        padding: '10px 0px',
        marginLeft: 'auto',
        '& svg': {
            color: '#99a1aa !important',
            cursor: 'pointer',
        },
        '& span': {
            color: '#353535',
        },
        '& .MuiFormControl-root': {
            margin: '0px 7px',
            width: '105px',
        },
        '& input': {
            padding: '2px 8px',
            fontSize: '13px',
            color: '#353535',
            width: '60px',
            height: '24px',
            textAlign: 'center',
        },
    },
    blueIcon: {
        fill: '#358be3 !important',
    },
    pageCount: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '50%',
        fontSize: '12px',
        '& p': {
            color: '#555e68 !important',
            fontSize: '14px',
        },
    },
    pageCountOff: {
        paddingRight: '8px',
    },
    leftPaginationContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        width: 'fit-content',
        borderLeft: '1px solid #C6C6C6',
        '& p': {
            color: '#555e68 !important',
            fontSize: '14px',
            '&:nth-child(1)': {
                paddingLeft: '10px',
                [theme.breakpoints.between('xs', 'sm')]: {
                    display: 'none',
                },
            },
        },
    },
    middlePaginationContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        paddingRight: '8px',
        paddingLeft: '8px',
        width: 'min-content',
    },
    rightPaginationContent: {
        borderLeft: '1px solid #C6C6C6',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'end',
        width: 'fit-content',
        '& p': {
            color: '#555e68 !important',
            fontSize: '14px',
            '&:nth-child(1)': {
                paddingLeft: '10px',
                [theme.breakpoints.between('xs', 'sm')]: {
                    display: 'none',
                },
            },
        },
    },
    resultOutOfStyle: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        padding: '2px 0px',
        margin: '0px 10px',
        '& p': {
            '&:nth-child(1)': {
                paddingLeft: '0px !important',
                paddingRight: '4px',
                [theme.breakpoints.between('xs', 'sm')]: {
                    display: 'block',
                },
            },
            '&:nth-child(2)': {
                paddingLeft: '4px !important',
                paddingRight: '0px',
            },
        },
    },
    valueOutOff: {
        padding: '0px 8px',
    },
}));

export default PaginationStyle;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserAccountInfo } from '../../types/UserDetailType';

export interface UserDataState {
  value: UserAccountInfo | null;
  key?: string;
}

const initialState: UserDataState = {
    value: null,
    key: '',
};

export const accountInfoIdSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        accountInfoId: (state, action: PayloadAction<UserAccountInfo>) => {
            state.value = action.payload;
        },
        handleUserDetails: (state, action: PayloadAction<string>) => {
            state.key = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { accountInfoId, handleUserDetails } = accountInfoIdSlice.actions;

export default accountInfoIdSlice.reducer;

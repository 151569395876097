import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const tabsStyle = makeStyles((theme:Theme) => ({
    mainContainer: {
        width: '100%',
        display: 'flex',
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'block',
        },
    },
    rightSection: {
        marginLeft: '232px',
        [theme.breakpoints.between('sm', 'md')]: {
            marginLeft: '232px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginLeft: '0px',
        },
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    leftSection: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '96%',
            position: 'relative',
            height: 'auto',
            backgroundColor: '#fff',
            padding: '10px 0px',
            margin: 'auto',
        },
        width: '237px',
        position: 'fixed',
        height: '100vh',
        backgroundColor: '#0A2A5B',
        padding: '43px 0px',
        overflowX: 'auto',
        '& .MuiTabScrollButton-root': {
            display: 'none',
        },
        '& button.Mui-selected': {
            [theme.breakpoints.between('xs', 'sm')]: {
                color: '#1A4C8E',
            },
            color: '#53B175',
        },
        '& .MuiTabs-root': {
            [theme.breakpoints.between('xs', 'sm')]: {
                borderBottom: '#E6E6E6 1px solid',
            },
            '& .MuiTabs-scroller': {
                [theme.breakpoints.between('xs', 'sm')]: {
                },
            },
            '& .MuiTabs-indicator': {
                [theme.breakpoints.between('xs', 'sm')]: {
                    top: '36px',
                },
            },
        },
        '& button': {
            [theme.breakpoints.between('xs', 'sm')]: {
                color: '#ACCDF7',
                fontSize: '11px',
                fontWeight: '600',
                lineHeight: '13.31px',
                padding: '12px 0px 12px 12px',
            },
            fontSize: '14px',
            fontWeight: '600',
            textTransform: 'capitalize',
            alignItems: 'flex-start',
            color: '#fff',
            letterSpacing: 'inherit',
            padding: '0px 0px 12px 35px',
        },
        '& .MuiTabs-indicator': {
            [theme.breakpoints.between('xs', 'sm')]: {
                background: '#1A4C8E',
                marginLeft: '7px',
                height: '6px !important',
                marginTop: '6px',
            },
            marginTop: '12px',
            height: '11px !important',
            width: '22px',
            borderRadius: '10px',
            background: '#53B175',
            left: '-5px',
        },
    },
    dashboardLink: {
        marginTop: '7px',
        display: 'flex',
        alignItems: 'end',
        height: 'calc(100vh - 300px)',
        paddingLeft: '20px',
        '& a': {
            color: '#eee',
            textDecoration: 'underline',
            fontSize: '14px',
            transition: '.6s',
            '&:hover': {
                fontSize: '15px',
                '& svg': {
                    fontSize: '20px',
                },
            },
        },
        '& svg': {
            verticalAlign: 'middle',
            fontSize: '18px',
            transition: '.6s',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'none',
        },
    },
}));

export default tabsStyle;

import { gql } from 'graphql-request';

const REFERRAL_USER_LIST = (referal_code: string | undefined) => gql`
query {
    adminUserReferrals(referral_code: "${referal_code}") {
      pageInfo {
        skip
        take
        total_count
        has_next_page
      }
      data {
        date_of_referral
        user_name
        no_of_transactions
      }
    }
  }
  `;

const UPDATE_REFERRAL_CODE = (referralCode: string, newReferralCodeName: string, prevSelfReferralCodeOrName: string) => gql`
  mutation {
    update_referral_code_name(
      referralCode: "${referralCode}"
      newReferralCodeName: "${newReferralCodeName}"
      prevSelfReferralCodeOrName: "${prevSelfReferralCodeOrName}"
    ) {
      message
      success
    }
  }
`;

export default {
    REFERRAL_USER_LIST,
    UPDATE_REFERRAL_CODE,
};

import React, {
    ChangeEvent,
    Dispatch, SetStateAction, useState,
} from 'react';
import {
    Box, DialogActions, DialogContent, TextField,
    DialogTitle, Dialog, Button, Grid, Typography, FormControl, Select, MenuItem, SelectChangeEvent,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useStyles from './UpdateTransactionStatusModel.style';
import { IAdminDashBoard, IUserLibrary } from '../../../../interfaces/User';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { STATUS_COLOR, URL_CONF } from '../../../../helper/constants';
import adminDashboard from '../../../../queries/adminDashboard';
import Loader from '../../../../components/loader';
import toast from 'react-hot-toast';

interface InviteProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    state?:IAdminDashBoard,
    setHandleAPIcall?: Dispatch<SetStateAction<boolean>>,
    // handleState?:(data:IAdminDashBoard) => void;
    tradeOrderData?: IUserLibrary,
    setTrackOrderFormModelOpen?: (data: boolean) => void,
    refetch?: () => void,
}
interface IUpdateStatus {
    id: string,
    transaction_status: string,
    remarks: string,
}

function UpdateTransactionStatusModel({
    open, setOpen, state, setHandleAPIcall, tradeOrderData, refetch, setTrackOrderFormModelOpen,
}: InviteProps) {
    const classes = useStyles();
    // const [select, setSelect] = useState('');
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<IUpdateStatus>({
        id: state?.id || '',
        transaction_status: state?.transaction_status || '',
        remarks: state?.remarks || '',
    });

    /**
     * Handles successful response from an API call.
     */
    const handleSucessRespose = () => {
        setLoader(false);
        setOpen(false);
        setData({
            id: '',
            transaction_status: '',
            remarks: '',
        });
        if (setHandleAPIcall) {
            setHandleAPIcall(true);
        }
        if (tradeOrderData?.id && refetch && setTrackOrderFormModelOpen) {
            refetch();
            setTrackOrderFormModelOpen(false);
        }
    };

    /**
     * Handles errors from an API call by displaying an error message.
     */
    const handleApiError = () => {
        toast.error('API ERROR');
    };

    const { mutate } = useGQLMutation(
        // KEY_CONF.USER_UPDATE_STATUS,
        'userUpdateStatus',
        adminDashboard.UPDATE_ADMIN_STATUS(
            data.id || tradeOrderData?.id || '',
            data.transaction_status || tradeOrderData?.transaction_status || '',
            data.remarks || tradeOrderData?.remarks || '',
        ),
        {
            onSuccess: handleSucessRespose,
            onError: handleApiError,
            enabled: false,
        },
        URL_CONF.ADMIN,
    );

    /**
     * Handles the change event of a select input.
     * @param {SelectChangeEvent} event - used to change event object.
     */
    const handleChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value,
        });
        // setSelect(event.target.value);
    };

    /**
     * Handles the click event to close a Dialog.
     */
    const handleClickClose = () => {
        setOpen(false);
    };

    /**
     * On mutation, enable the loader
     */
    const handleContinueClose = () => {
        if (state?.id || tradeOrderData?.id) {
            mutate({});
            // refetch();
            setLoader(true);
        }
    };

    return (
        <Box>
            {loader && <Loader />}
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxNo}>
                    <Typography variant="h2">Select Status to update the transaction.</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            <Typography className={classes.blueHead} variant="body1">
                                Status
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={data?.transaction_status || tradeOrderData?.transaction_status}
                                    onChange={handleChange}
                                    name="transaction_status"
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem value="CANCELLED">Cancelled</MenuItem>
                                    <MenuItem value="PENDING">Pending</MenuItem>
                                    <MenuItem value="COMPLETED">Completed</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {data?.transaction_status === STATUS_COLOR.CANCELLED
                        ? (
                            <Grid container spacing={2} className={classes.sectionBody}>
                                <Grid item xs={12} className={classes.borderStyleNo}>
                                    <Typography className={classes.blueHead} variant="body1">
                                        Reason for Cancellation
                                    </Typography>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={4}
                                        name="remarks"
                                        value={data?.remarks || tradeOrderData?.remarks}
                                        onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        ) : null}
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.rightSideBtn}>
                        <Box className={classes.footerBtnFillGrey}>
                            <Button onClick={handleClickClose}>Cancel</Button>
                        </Box>
                        <Box className={classes.footerBtnFillBlue}>
                            <Button onClick={handleContinueClose}>
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default UpdateTransactionStatusModel;

import { MouseEvent, useState } from 'react';
import NavbarStyle from './Navbar.style';
import logo from '../../assets/logo.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_OTC, PATH_PROFILE } from '../../routers/path';
import { JSONParse, getDecodedItemDataFromLocalStorage } from '../../helper/storageHelper';
import { ISingnUpInfo } from '../../interfaces/Auth';
import { useQueryClient } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import { ADMIN_ROLE, NAVBAR_MENU, USER_VERIFIED_STATUS } from '../../helper/constants';
import ChangePasswordModal from './ChangePasswordModal';
import { useLogout } from '../../hooks/useLogout';
import { Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

function Navbar() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const logout = useLogout();
    const userDataString = getDecodedItemDataFromLocalStorage('userData');
    const userData: ISingnUpInfo | null = userDataString ? JSONParse<ISingnUpInfo>(userDataString) : null;
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const classes = NavbarStyle();
    const [modal, setModal] = useState(false);
    const enableModal = (val: boolean) => {
        setModal(val);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClick = (e: MouseEvent, type: string) => {
        e.stopPropagation();
        let path: string | null = null;
        switch (type) {
        case NAVBAR_MENU.DASHBOARD:
            path = PATH_OTC.dashboard;
            setAnchorEl(null);
            break;
        case NAVBAR_MENU.ACCOUNT:
            path = PATH_PROFILE.profile;
            setAnchorEl(null);
            break;
        case NAVBAR_MENU.REFERRAL:
            path = PATH_OTC.partnerrefer;
            setAnchorEl(null);
            break;
        case NAVBAR_MENU.LOGOUT:
            path = PATH_AUTH.auth;
            logout();
            queryClient.clear();
            break;
        case NAVBAR_MENU.CHANGE_PASSWORD:
            setAnchorEl(null);
            enableModal(true);
            break;
        default:
            break;
        }
        if (path) {
            navigate(path);
        }
    };

    const isAdmin = userData?.role === ADMIN_ROLE;
    const isUserOnboarded = userData?.pending_stage === USER_VERIFIED_STATUS;

    return (
        <div className={classes.navbarTop}>
            <div className={classes.navbar}>
                <div className={`'logo' ${classes.homeIconStyle}`}>
                    <Link to="/dashboard"><img src={logo} alt="Logo" /></Link>
                </div>
                <div className="profile">
                    <Typography className={classes.navbarDashboardStyle} variant="body1">
                        {/* <NavLink to="#">Dashboard</NavLink> */}
                    </Typography>
                    <IconButton
                        disableRipple
                        className={open ? 'dropDownColor' : 'downarrow'}
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <span><AccountCircleOutlinedIcon /></span>
                        <span className="profileName">{userData?.full_name}</span>
                        {!open ? (
                            <span><KeyboardArrowDownOutlinedIcon /></span>
                        ) : (
                            <span><KeyboardArrowUpOutlinedIcon /></span>
                        )}
                    </IconButton>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    className={`menubox ${classes.dropDownMenu}`}
                >
                    {!isAdmin && isUserOnboarded ? (
                        <div>
                            <MenuItem onClick={(e) => handleMenuClick(e, NAVBAR_MENU.ACCOUNT)} divider>
                                Account
                            </MenuItem>
                            <MenuItem onClick={(e) => handleMenuClick(e, NAVBAR_MENU.REFERRAL)} divider>
                                Referral Program
                            </MenuItem>
                        </div>
                    ) : null}
                    <MenuItem onClick={(e) => handleMenuClick(e, NAVBAR_MENU.CHANGE_PASSWORD)} divider>
                        Change Password
                    </MenuItem>
                    {!isAdmin && isUserOnboarded
                    && (
                        <MenuItem onClick={(e) => handleMenuClick(e, NAVBAR_MENU.DASHBOARD)} divider>
                            Dashboard
                        </MenuItem>
                    )}
                    <MenuItem onClick={(e) => handleMenuClick(e, NAVBAR_MENU.LOGOUT)} divider>
                        Logout
                    </MenuItem>
                    <span className="closeBtn"><CloseIcon /></span>
                </Menu>
            </div>
            <ChangePasswordModal modal={modal} enableModal={enableModal} />
        </div>
    );
}
export default Navbar;

/* eslint-disable jsx-a11y/label-has-associated-control */
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Box, Grid, Typography, FormControl, Select, MenuItem,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import apple from '../../../assets/apple.svg';
import Loader from '../../../components/loader';
import SuccessPopup from '../../../components/successpopup';
import {
    ERRORS, KEY_CONF, URL_CONF,
} from '../../../helper/constants';
import {
    capitalize,
    emailValidator, passwordValidator, phoneNumberValidator, userNameValidator,
} from '../../../helper/validators';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import {
    ISingnUpState, ISingnUpStateError,
} from '../../../interfaces/Auth';
import userQueries from '../../../queries/user';
import { PATH_AUTH } from '../../../routers/path';
import { QueryErrorResponse } from '../../../types/UserDetailType';
import useStyles from '../Auth.style';
import AccountType from '../components/accounttypepopup';
import { useQueryClient } from '@tanstack/react-query';
import GoogleSSOAuth from '../googlesso';

interface PhoneInputProps {
    countryCode: string;
    dialCode: string;
    format: string;
    name: string;
}
interface BookProps {
    tittleUserName: string;
    userType: string;
}

const validateData = {
    name: 'name',
    email: 'email',
    phoneNumber: 'PhoneNumber',
    password: 'password',
    referralCode: 'referralCode',
};

function Personal({ tittleUserName, userType }: BookProps) {
    const classes = useStyles();
    const queryClient = useQueryClient();
    // to open popup for Account type during Google SSO
    const [showAccountTypePopup, setShowAccountTypePopup] = useState(false);
    const [loader] = useState(false);
    const [showEmailVerifyPopup, setShowEmailVerifyPopup] = useState(false);
    // contains data for the user like userId to recognize the user
    const [userId, setUserIdData] = useState<string | null>(null);
    const [state, setState] = useState<ISingnUpState>({
        email: '',
        name: '',
        password: '',
        phoneNumber: '',
        referralCode: '',
        userType,
        countryCode: '',
        contact: '',
    });
    const [errorState, setErrorState] = useState<ISingnUpStateError>({
        email: '',
        name: '',
        password: '',
        phoneNumber: '',
    });
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const validateFields = (name: string, value: string) => {
        if (value.trim().length === 0) {
            setErrorState((prevState) => ({
                ...prevState,
                [name]: `${capitalize(name)} is required`,
            }));
            return;
        }
        switch (name) {
        case validateData.name: setErrorState((prevState) => ({
            ...prevState,
            name: !userNameValidator(value) ? ERRORS.USERNAME_ERROR : null,
        }));
            break;
        case validateData.email:
            setErrorState((prevState) => ({
                ...prevState,
                email: !emailValidator(value) ? ERRORS.EMAIL_ERROR : null,
            }));
            break;
        case validateData.password:
            setErrorState((prevState) => ({
                ...prevState,
                password: !passwordValidator(value) ? ERRORS.PASSWORD_ERROR : null,
            }));
            break;
        case validateData.phoneNumber:
            setErrorState((prevState) => ({
                ...prevState,
                phoneNumber: !phoneNumberValidator(value) ? ERRORS.PHONE_ERROR : null,
            }));
            break;
        default:
            break;
        }
    };

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value.trim().length === 0) {
            setErrorState((prevState) => ({
                ...prevState,
                [name]: `${capitalize(name)} is required`,
            }));
        } else {
            validateFields(name, value);
        }
    };

    const handleChangePass = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value.trim().length === 0) {
            setErrorState((prevState) => ({
                ...prevState,
                [name]: `${capitalize(name)} is required`,
            }));
        } else {
            validateFields(name, value);
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    /**
     * Function to validate data
     */
    const validateUserData = () => {
        const {
            email, password, name, phoneNumber,
        } = state;
        if (emailValidator(email) && passwordValidator(password) && userNameValidator(name) && phoneNumberValidator(phoneNumber)) {
            return true;
        }
        validateFields('email', email);
        validateFields('password', password);
        validateFields('name', name);
        validateFields('phoneNumber', phoneNumber);
        return false;
    };

    const handlePhone = (value: string | null, data: PhoneInputProps) => {
        setState((prevState) => ({
            ...prevState,
            phoneNumber: value !== null ? `${value}` : '',
            countryCode: data.dialCode,
            contact: value !== null ? value.slice(data.dialCode.length) : '',
        }));
        if (value === '' && value.trim().length === 0) {
            setErrorState((prevState) => ({
                ...prevState,
                phoneNumber: `${capitalize(validateData.phoneNumber)} is required`,
            }));
        } else {
            validateFields(validateData.phoneNumber, value || validateData.phoneNumber);
        }
    };

    // Error handling---------------------------------------------
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        if (message) {
            toast.error(message);
        }
        return true;
    };

    // handling user Register -------------------------------------
    const isCall = false;
    const {
        data: userData, isFetching, refetch, isError, error,
    } = useGQLQuery(
        [KEY_CONF.REGISTER],
        userQueries.USER_SIGNUP(state),
        {},
        {
            enabled: isCall,
        },
        URL_CONF.AUTHENTICATION,
    );

    useEffect(() => {
        if (isError && error !== null) {
            handleApiError(error);
            // clears all connected caches
            queryClient.clear();
        }
    }, [error, isError, queryClient]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isChecked && validateUserData()) {
            refetch();
        }
        // const valuesErrostate = Object.values(errorState);
        // const result = valuesErrostate.filter((value) => value === null);
        // if (result.length === 4) {
        //     setOpen(true);
        //     setState(
        //         {
        //             email: '',
        //             name: '',
        //             password: '',
        //             phoneNumber: '',
        //             referralCode: '',
        //             userType,
        //             countryCode: '',
        //             contact: '',
        //         },
        //     );
        //     setIsChecked(false);
        // }
        // Object.entries(state).forEach(([key, value]) => {
        //     if (typeof value === 'string' && value.trim().length === 0) {
        //         setErrorState((prevState) => ({
        //             ...prevState,
        //             [key]: `${capitalize(key)} is required`,
        //         }));
        //     } else {
        //         validateFields(key, value as string);
        //     }
        // });
    };

    useEffect(() => {
        if (userData && userData.register && userData.register) {
            setShowEmailVerifyPopup(true);
        }
    }, [userData]);

    //-----------------------------------------------------------------------

    const handleCloseSuccessPopup = () => {
        setShowEmailVerifyPopup(false);
        navigate(PATH_AUTH.children.login);
        // clears all connected caches
        queryClient.clear();
    };

    useEffect(() => {
        const splitData = location.pathname.split('/');
        if (splitData.length > 2) {
            setState({
                ...state,
                referralCode: splitData[2],
            });
        }
    }, [location.pathname, state]);

    const handleAccountTypePopUp = () => {
        setShowAccountTypePopup(false);
    };

    return (
        <div>
            {(isFetching || loader) && <Loader />}
            {showEmailVerifyPopup && (
                <SuccessPopup
                    open={showEmailVerifyPopup}
                    handleClose={handleCloseSuccessPopup}
                    title="You have Successfully Signed Up"
                    subtitle="Please check your inbox to verify e-mail address."
                />
            )}
            {showAccountTypePopup && (
                <AccountType
                    open={showAccountTypePopup}
                    handleClose={handleAccountTypePopUp}
                    userId={userId}
                />
            )}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4} className={classes.sectionBodyDrop}>
                    <Grid item sm={6} xs={12} className={classes.rightBodyInputMobile}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>
                                Account Type*
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem>Personal</MenuItem>
                                    <MenuItem value="two">Business</MenuItem>
                                    <MenuItem value="three">Charity</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>
                                {tittleUserName}
                                {' '}
                                Name*
                            </InputLabel>
                            <TextField
                                id="outlined-basic-email"
                                type="user"
                                name="name"
                                value={state.name}
                                autoComplete="off"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangeName(e)}
                                placeholder="Enter Your Name"
                                variant="outlined"
                                className={(state.name && state.name.length > 0) ? classes.borderStyle : ''}
                            />
                            <span className={classes.errorStyle}>{errorState.name}</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box className={`${classes.rightBodyInput} ${classes.telephoneBoxStyle}`}>
                            <InputLabel>Phone Number *</InputLabel>
                            <PhoneInput
                                country="us"
                                placeholder="Enter Your Phone Number"
                                value={state.phoneNumber}
                                onChange={handlePhone}
                                disableCountryGuess
                            />
                            <span className={classes.errorStyle}>{errorState.phoneNumber}</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>Email *</InputLabel>
                            <TextField
                                id="outlined-basic-email"
                                type="email"
                                name="email"
                                value={state.email}
                                autoComplete="off"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangeName(e)}
                                placeholder="Enter your Email Address"
                                variant="outlined"
                                className={(state.email && state.email.length > 0) ? classes.borderStyle : ''}
                            />
                            <span className={classes.errorStyle}>{errorState.email}</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>Password *</InputLabel>
                            <TextField
                                id="outlined-basic"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={state.password}
                                placeholder="Please Enter Your Password"
                                variant="outlined"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangePass(e)}
                                className={(state.password && state.password.length > 0) ? classes.borderStyle : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={handleTogglePasswordVisibility}
                                                aria-label="toggle password visibility"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <span className={classes.errorStyle}>{errorState.password}</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>Referral Code (Optional)</InputLabel>
                            <TextField
                                id="outlined-basic"
                                value={state.referralCode}
                                name="referralCode"
                                variant="outlined"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangePass(e)}
                                className={(state.referralCode && state.referralCode.length > 0) ? classes.borderStyle : ''}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box className={`checkBxStle ${classes.checkboxStyle}`}>
                    <input type="checkbox" id="html" checked={isChecked} onChange={handleCheckboxChange} />
                    <label htmlFor="html">
                        I accept the
                        <a href="/VPay Terms of Services.pdf" download>
                            <span>Terms & Conditions</span>
                        </a>
                    </label>
                </Box>
                <Box className={
                    state.name
                        && state.email
                        && state.password
                        && state.phoneNumber && isChecked
                        ? classes.rightBodyButton
                        : classes.rightBodyButtonDisabled
                }
                >
                    <button
                        type="submit"
                        disabled={!(state.name
                            && state.email
                            && state.password
                            && state.phoneNumber && isChecked)}
                    >
                        Sign Up
                    </button>
                </Box>
                <Box className={classes.rightBodyOrWith}>
                    <Typography variant="body1">
                        <span>Or With</span>
                    </Typography>
                </Box>
                <Box className={classes.authImg}>
                    <GoogleSSOAuth setUserIdData={setUserIdData} setShowAccountTypePopup={setShowAccountTypePopup} />
                    {/* <img src={apple} alt="apple" /> */}
                </Box>
                <Box className={classes.authContent}>
                    <Typography variant="body1">
                        Already have an account?
                        <span><NavLink to="/login">Log In</NavLink></span>
                    </Typography>
                </Box>
            </form>
        </div>
    );
}

export default Personal;

import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Dialog,
    IconButton, InputAdornment, InputLabel,
    SelectChangeEvent, TextField, Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
    ChangeEvent,
    FormEvent,
    MouseEvent,
    useState,
} from 'react';
import toast from 'react-hot-toast';
import { ERRORS, KEY_CONF, MESSAGES } from '../../helper/constants';
import { passwordValidator } from '../../helper/validators';
import { useGQLMutation } from '../../hooks/useGQLMutation';
import { useLogout } from '../../hooks/useLogout';
import {
    IChangePasswordModal, IErrorPassoword, IStatePassword, IStatePasswordVisiblity,
} from '../../interfaces/User';
import userQueries from '../../queries/user';
import useStyles from '../../screens/auth/Auth.style';
import { QueryErrorResponse } from '../../types/UserDetailType';
import Loader from '../loader';
import SuccessPopup from '../successpopup';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from '../../routers/path';

function ChangePasswordModal(props: IChangePasswordModal) {
    const { modal, enableModal } = props;
    const classesModal = useStyles();
    const logout = useLogout();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [errorState, setErrorState] = useState<IErrorPassoword>({
        oldPasswordError: '',
        newPasswordError: '',
        confirmPasswordError: '',
    });
    const [state, setState] = useState<IStatePassword>({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [handleIcon, setHandleIcon] = useState<IStatePasswordVisiblity>({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [showChangeSucessPopup, setChangeSuccessPopup] = useState<boolean>(false);

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        if (message) {
            toast.error(message);
        }
        setLoading(false);
    };

    const handleChangePasswordModal = () => {
        setState({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
        setHandleIcon({
            oldPassword: false,
            newPassword: false,
            confirmPassword: false,
        });
        setErrorState({
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
        });
    };

    const handleClosePasswordModal = () => {
        handleChangePasswordModal(); // empty the state
        setChangeSuccessPopup(false); // making the sucess as false
        enableModal(false); // making the modal disappear
    };

    const handleSucessRespose = () => {
        handleChangePasswordModal(); // empty the state
        enableModal(false);
        setLoading(false);
        const message = 'Password Changed Sucessfully';
        setChangeSuccessPopup(true);
        toast.success(message);
    };

    const { mutate } = useGQLMutation(
        KEY_CONF.CHANGE_PASSWORD,
        userQueries.CHANGE_PASSWORD(state.oldPassword, state.newPassword, state.confirmPassword),
        {
            onSuccess: handleSucessRespose,
            onError: handleApiError,
            enabled: false,
        },
    );

    const handleTogglePasswordVisibility = (event: MouseEvent<HTMLButtonElement>) => {
        const { name } = event.currentTarget;
        if (name && name in handleIcon) {
            setHandleIcon({
                ...handleIcon,
                [name]: !handleIcon[name],
            });
        }
    };

    const validateFields = (name: string, value: string) => {
        switch (name) {
        case 'oldPassword': setErrorState({
            ...errorState,
            oldPasswordError: !passwordValidator(value) ? ERRORS.PASSWORD_OLD : '',
        });
            break;
        case 'newPassword':
            setErrorState({
                ...errorState,
                newPasswordError: !passwordValidator(value) ? ERRORS.PASSWORD_NEW : '',
            });

            break;
        case 'confirmPassword':
            setErrorState({
                ...errorState,
                confirmPasswordError: !passwordValidator(value) ? ERRORS.PASSWORD_CONFIRM : '',
            });
            break;
        default:
            break;
        }
    };

    const clickValidate = () => {
        const { oldPassword, newPassword, confirmPassword } = state;
        setErrorState({
            ...errorState,
            oldPasswordError: !passwordValidator(oldPassword) ? ERRORS.PASSWORD_OLD : '',
            newPasswordError: !passwordValidator(newPassword) ? ERRORS.PASSWORD_NEW : '',
            confirmPasswordError: !passwordValidator(confirmPassword) ? ERRORS.PASSWORD_CONFIRM : '',
        });
    };

    const handleChangeNew = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
        if (name === 'oldPassword' && errorState.newPasswordError === ERRORS.OLD_NEW) {
            errorState.newPasswordError = '';
        }
        if (name === 'newPassword' && errorState.confirmPasswordError === ERRORS.PASSWORD_CONFIRM2) {
            errorState.confirmPasswordError = '';
        }
        validateFields(name, value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        clickValidate();
        // confirm password Error when new password is different from confirm password
        if (state.newPassword !== state.confirmPassword && state.confirmPassword) {
            setErrorState({
                ...errorState,
                confirmPasswordError: state.newPassword !== state.confirmPassword ? ERRORS.PASSWORD_CONFIRM2 : '',
            });
        }
        // new password error when old password is similar with the new password
        if (state.newPassword === state.oldPassword && state.oldPassword) {
            setErrorState({
                ...errorState,
                newPasswordError: state.newPassword === state.oldPassword ? ERRORS.OLD_NEW : '',
            });
        }
        const errorPassword = Object.values(errorState).every((val) => val === '');
        const statePassword = Object.values(state).every((val) => val !== '');

        if (errorPassword && statePassword && state.confirmPassword === state.newPassword
            && state.confirmPassword !== state.oldPassword
            && state.confirmPassword) {
            setLoading(true);
            mutate({});
        }
    };
    const handleLoggedOut = () => {
        logout();
        queryClient.clear();
        navigate(PATH_AUTH.children.login);
    };

    const passwordInputProps = (passwordName : string) => (
        <InputAdornment position="end">
            <IconButton
                edge="end"
                onClick={handleTogglePasswordVisibility}
                aria-label="toggle password visibility"
                name={passwordName}
            >
                {handleIcon[passwordName] ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    );

    return (
        <>
            <div>
                {showChangeSucessPopup && (
                    <SuccessPopup
                        open={showChangeSucessPopup}
                        handleClose={handleLoggedOut}
                        title={MESSAGES.SUCCESS_CHANGE_PASSWORD}
                        subtitle=""
                    />
                )}
            </div>
            <Dialog
                open={modal}
                onClose={handleClosePasswordModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classesModal.modelStyle}
            >
                <Box className={classesModal.headerAccountStyle}>
                    <Typography variant="h5"><span><CloseIcon onClick={handleClosePasswordModal} /></span></Typography>
                    <Typography id="keep-mounted-modal-title" variant="h2">
                        Change Password
                    </Typography>
                </Box>
                {loading && <Loader />}

                <form onSubmit={handleSubmit}>
                    <Box className={classesModal.rightBodyInputPop}>
                        <InputLabel>Old Password *</InputLabel>
                        <TextField
                            id="outlined-basic-email"
                            type={handleIcon.oldPassword ? 'text' : 'password'}
                            autoComplete="off"
                            name="oldPassword"
                            value={state.oldPassword}
                            placeholder="Old Password"
                            variant="outlined"
                            className={classesModal.borderStyle}
                            InputProps={{
                                endAdornment: passwordInputProps('oldPassword'),
                            }}
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChangeNew(e)}
                        />
                        <span className={classesModal.errorStyle}>{errorState.oldPasswordError}</span>
                    </Box>
                    <Box className={classesModal.rightBodyInputPop}>
                        <InputLabel>New Password*</InputLabel>
                        <TextField
                            id="outlined-basic-email"
                            type={handleIcon.newPassword ? 'text' : 'password'}
                            autoComplete="off"
                            value={state.newPassword}
                            name="newPassword"
                            placeholder="New Password"
                            variant="outlined"
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChangeNew(e)}
                            className={classesModal.borderStyle}
                            InputProps={{
                                endAdornment: passwordInputProps('newPassword'),
                            }}
                        />
                        <span className={classesModal.errorStyle}>{errorState.newPasswordError}</span>
                    </Box>
                    <Box className={classesModal.rightBodyInputPop}>
                        <InputLabel>Confirm Password*</InputLabel>
                        <TextField
                            id="outlined-basic-email"
                            type={handleIcon.confirmPassword ? 'text' : 'password'}
                            autoComplete="off"
                            value={state.confirmPassword}
                            name="confirmPassword"
                            onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChangeNew(e)}
                            placeholder="Confirm Password"
                            variant="outlined"
                            className={classesModal.borderStyle}
                            InputProps={{
                                endAdornment: passwordInputProps('confirmPassword'),
                            }}
                        />
                        <span className={classesModal.errorStyle}>{errorState.confirmPasswordError}</span>
                    </Box>
                    <Box className={classesModal.rightBodyButtonPop}>
                        <button type="submit">Change Password</button>
                    </Box>
                </form>
            </Dialog>
        </>
    );
}

export default ChangePasswordModal;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme:Theme) => ({
    selectRadio: {
        '& .MuiFormControlLabel-label': {
            color: '#353535',
            fontSize: '14px !important',
        },
    },
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '10px !important',
            minWidth: '35%',
            width: '35%',
            [theme.breakpoints.between('sm', 'md')]: {
                minWidth: '80%',
                width: '80%',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                minWidth: '98%',
                width: '98%',
            },
        },
    },
    dilogBoxSection: {
        padding: '24px !important',
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            cursor: 'pointer',
        },
    },
    dialogBody: {
        padding: '0px 30px 25px 30px !important',
        '& input': {
            padding: '6px',
            height: '27px',
            fontSize: '15px',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            color: '#6A6A6A',
            fontFamily: 'Inter-Medium',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
        '& .Mui-disabled': {
            backgroundColor: '#E0E0E0',
        },
    },
    sectionBody: {
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '15px',
            height: '27px !important',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            color: '#6A6A6A',
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
    },
    dilogBoxSectionFooter: {
        padding: '24px !important',
        justifyContent: 'space-between !important',
    },
    insideBoxStyle: {
        color: 'rgba(16, 66, 127, 1)',
        border: '1px solid rgba(198, 198, 198, 1)',
        padding: '6px 22px 6px 22px',
        borderRadius: '12px',
        marginBottom: '10px',
        '& button': {
            color: '#1968B6',
            borderRadius: '50px',
            cursor: 'pointer',
            textAlign: 'center',
            textTransform: 'capitalize',
            padding: '2px 10px',
            border: '1px solid #1968B6',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#1968B6',
                color: '#ffffff',
                transform: 'scale(1.02)',
                transition: '0.2s',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 12,
                width: '100px',
            },
        },
    },
    backgroundInsideBoxStyle: {
        color: 'rgba(16, 66, 127, 1)',
        border: '1px solid rgba(198, 198, 198, 1)',
        padding: '6px 22px 6px 22px',
        borderRadius: '12px',
        marginBottom: '10px',
        '& button': {
            color: '#fff',
            borderRadius: '50px',
            backgroundColor: 'rgba(16, 66, 127, 1)',
            cursor: 'pointer',
            textAlign: 'center',
            textTransform: 'capitalize',
            padding: '2px 10px',
            border: '1px solid #1968B6',
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: 'rgba(16, 66, 127, 1)',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 12,
                width: '100px',
            },
        },
    },
    virtualInsideBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    footerBtnFillBlue: {
        '& button': {
            color: '#fff',
            backgroundColor: '#1968B6',
            borderRadius: '5px',
            cursor: 'pointer',
            textAlign: 'center',
            textTransform: 'capitalize',
            padding: '4px 0px',
            border: '2px solid #1968B6',
            width: '145px',
            fontSize: 17,
            fontFamily: 'Inter-Regular',
            '&:hover': {
                backgroundColor: '#1968B6',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 12,
                width: '100px',
            },
        },
        '& .Mui-disabled': {
            // backgroundColor: '#E6E6E6',
            backgroundColor: '#E6E6E6',
            color: '#565656 !important',
            border: 'none',
        },
    },
    footerBtnFillGrey: {
        '& button': {
            color: '#565656',
            backgroundColor: '#E6E6E6',
            borderRadius: '5px',
            cursor: 'pointer',
            textAlign: 'center',
            textTransform: 'capitalize',
            padding: '4px 0px',
            border: '2px solid #E6E6E6',
            width: '100px',
            fontSize: 17,
            fontFamily: 'Inter-Regular',
            '&:hover': {
                backgroundColor: '#E6E6E6',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 12,
                width: '100px',
            },
        },
    },
    footerBtnOutlineGrey: {
        '& button': {
            color: '#B0B0B0',
            backgroundColor: '#fff',
            borderRadius: '5px',
            cursor: 'pointer',
            textAlign: 'center',
            textTransform: 'capitalize',
            padding: '4px 0px',
            border: '1px solid #B0B0B0',
            width: '103px',
            fontSize: 17,
            fontFamily: 'Inter-Regular',
            '&:hover': {
                backgroundColor: '#fff',
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 12,
                width: '100px',
            },
        },
    },
    rightSideBtn: {
        display: 'flex',
        gap: '10px',
    },
    tableStyles: {
        '& table': {
            width: '100%',
            borderRadius: '5px 5px 0px 0px',
            border: '1px solid #DFDFDF',
            borderSpacing: '0px',
            borderBottom: 'none',
            fontSize: '14px',
            fontFamily: 'Inter-Regular',
        },
        '& tr': {
            borderBottom: '1px solid #DFDFDF',
        },
        '& th': {
            borderBottom: '1px solid #DFDFDF',
            textAlign: 'left',
            color: '#7E7E7E',
            padding: '13px 4px',
        },
        '& td': {
            borderBottom: '1px solid #DFDFDF',
            padding: '13px 4px',
        },

    },
    virtualEmailBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'rgba(16, 66, 127, 1)',
        marginTop: '10px',
        '& span': {
            display: 'inline-block',
            textAlign: 'center',
            color: 'rgba(16, 66, 127, 1)',
            width: '20%',
            cursor: 'pointer',
            '& svg': {
                fontSize: '16px',
            },
        },
    },
}));

import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validators';
import { ISingnUpState } from '../interfaces/Auth';

// ------------------For manual Login-------------------------------------------
const USER_LOGIN = (email: string, password: string) => gql`
    query {
        login (
            email: ${addEscapeCharacters(email)},
            password: ${addEscapeCharacters(password)},
        ) {
            access_token
            refresh_token
            email
            info {
                id
                role
                full_name
                user_type
                contact_country_code
                contact
                dob
                plaid_verified
                pending_stage
                self_referral_code
                self_referral_code_name
            }
        }
  }
  `;
// --------------------------------------------------------------------------------------

// -------------------For Manual Signup--------------------------------------------------
const USER_SIGNUP = (data: ISingnUpState) => gql`
mutation {
    register (
            email: ${addEscapeCharacters(data.email)},
            password: ${addEscapeCharacters(data.password)},
            ${data.referralCode ? `referal_code: "${data.referralCode}",` : ''}
            full_name:${addEscapeCharacters(data.name)},
            user_type:${Number(data.userType)},
            contact_country_code:${addEscapeCharacters(data.countryCode)},
            contact:${Number(data.contact)},
        ) {
            access_token
            email
            message
            info {
                full_name
                user_type
            }
        }
  }
  `;
//---------------------------------------------------------------------------

// ----------- For GOOGLE SSO Login --------------------------------------
const GOOGLE_SSO_LOGIN = (code: string) => gql`
    mutation {
        sso_login(sso_code: "${code}") {
            open_modal
            user_id
            access_token
            refresh_token
            email
            message
            info {
                contact
                contact_country_code
                user_type
                full_name
                self_referral_code
                self_referral_code_name
                pending_stage
            }
        }
    }
`;
//----------------------------------------------------------------------

// ----------------- For GOOGLE SSO Registration -------------------------------------------------
const GOOGLE_SSO_REGISTER = (
    userId: string,
    accountType: number,
    referralCode?: string,
) => gql`
  mutation {
      sso_register(
          user_id: "${userId}",
          user_type: ${accountType},
          ${referralCode ? `referral_code: "${referralCode}"` : ''}
      ) {
          access_token
          refresh_token
          email
          message
          info {
              user_type
              full_name
          }
      }
  }
`;
// ------------------------------------------------------------------------------------------------

// --------------- For Forgot Password --------------------------
const FORGOT_PASSWORD = (email: string) => gql`
    query {
        forgot_password(
            email: ${addEscapeCharacters(email)}
            ) {
                success
                message
            }
        }
`;
//---------------------------------------------------------------

// ---------------- For Reset Password -----------------------------------------------------
const RESET_PASSWORD = (
    password: string,
    confirmPassword: string,
    token: string,
) => gql`
    mutation {
        reset_password(
            password: ${addEscapeCharacters(password)},
            confirmPassword: ${addEscapeCharacters(confirmPassword)},
            token: ${addEscapeCharacters(token)}
        ) {
            success
            message
        }
    }
`;
//-------------------------------------------------------------------------------------------

// ----------------------For Verifying email----------------------------------
const VERIFY_EMAIL = (token: string) => gql`
    mutation {
        verify_email(
            token: ${addEscapeCharacters(token)}
        ) {
            success
            message
        }
    }
`;
// ---------------------------------------------------------------------------

// --------------- For Change Password --------------------------
const CHANGE_PASSWORD = (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
) => gql`
mutation {
    change_password(
        oldPassword: ${addEscapeCharacters(oldPassword)},
        newPassword: ${addEscapeCharacters(newPassword)},
        confirmPassword: ${addEscapeCharacters(confirmPassword)},
    ) {
        success
        message
    }
}
`;
//---------------------------------------------------------------

// --------------- For Refresh Token --------------------------
const REFRESH_TOKEN = (refreshToken: string) => gql`
    query {
        refresh_token(
            refresh_token: ${addEscapeCharacters(refreshToken)}
            ) {
                access_token
                refresh_token
            }
        }
`;
//---------------------------------------------------------------

const ADD_USER_PERSONAL_INFORMATION = () => gql`
  mutation (
    $addressLineOne: String!,
    $addressLineTwo: String!,
    $country: String!,
    $city: String!,
    $state: String!,
    $postal_code: String!,
    $firstName: String!,
    $lastName: String!,
    $settlementCurrency: String!,
    $dob: String!,
  ) {
    add_personal_information (
      data: {
        user_info: {
            first_name: $firstName
            last_name: $lastName
            settlement_currency: $settlementCurrency
            dob: $dob
        }
        address: {
            address_line_1: $addressLineOne
            address_line_2: $addressLineTwo
            country: $country
            city: $city
            state: $state
            postal_code: $postal_code
          }
      }      
    ){
      success
      message
    }
  }
`;

const UPDATE_USER_STATUS = (status: string) => gql`
query{
    update_user_status(status: ${addEscapeCharacters(status)}){
      success
      message
    }
  }
`;

const CREATE_LOG = () => gql`
  mutation ($user_logs: String!) {
    add_user_logs(user_logs: $user_logs) {
      message
    }
  }
`;

// ------------------For User Logout---------------------
const USER_LOGOUT = () => gql`
  mutation {
    user_logout {
      success
      message
    }
  }
`;
// ------------------------------------------------------

export default {
    USER_LOGIN,
    USER_SIGNUP,
    GOOGLE_SSO_LOGIN,
    GOOGLE_SSO_REGISTER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    VERIFY_EMAIL,
    CHANGE_PASSWORD,
    REFRESH_TOKEN,
    ADD_USER_PERSONAL_INFORMATION,
    UPDATE_USER_STATUS,
    CREATE_LOG,
    USER_LOGOUT,
};

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const AccountStyle = makeStyles((theme:Theme) => ({
    mainSection: {
        padding: '48px 48px 94px 48px !important',
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '48px 25px 94px 25px !important',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '10px 15px 94px 15px !important',
        },
        '& .MuiOutlinedInput-root': {
            background: '#F5F5F5',
        },
    },
    subHeaderText: {
        display: 'flex',
        alignItems: 'center',
        color: '#1F1F1F !important',
        fontSize: '18px',
        fontFamily: 'Inter-Medium !important',
        '& img': {
            marginRight: '6px',
        },
    },
    sectionBody: {
        marginTop: '0px !important',
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
            },
        },
        '& input': {
            padding: '10.45px 10px',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
        '& .MuiSelect-select': {
            padding: '10px',
            fontSize: '14px',
            height: '22px !important',
            borderRadius: '5px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },
        '& .MuiGrid-item': {
            [theme.breakpoints.between('xs', 'sm')]: {
                paddingBottom: '0px',
            },
            paddingTop: '0px !important',
            paddingBottom: '0px',
        },
        '& .MuiGrid-item:first-child': {
            [theme.breakpoints.between('xs', 'sm')]: {
                paddingBottom: '0px',
            },
            paddingBottom: '8px',
        },
    },
    labelText: {
        margin: '24px 0px 9px 0px !important',
        color: '#1A4C8E',
        fontWeight: '500',
    },
}));

export default AccountStyle;

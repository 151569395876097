import React, { useState, ReactNode } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import tabsStyle from './Tabs.style';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { PATH_OTC } from '../../../routers/path';

interface VerticalTabsWithSectionsProps {
    tabs: { id: number; value: string }[];
    sections: { id: number, value: ReactNode }[];
}

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function TabPanel({ children, value, index }: TabPanelProps) {
    return (
        <div hidden={value !== index}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function VerticalTabsWithSections({ tabs, sections }: VerticalTabsWithSectionsProps) {
    const classes = tabsStyle();
    const [value, setValue] = useState(0);
    const smallScreen = useMediaQuery('(max-width: 767px)');
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box className={classes.mainContainer}>
            {/* Vertical Tabs */}
            <Box className={classes.leftSection}>
                <Tabs
                    orientation={smallScreen ? 'horizontal' : 'vertical'}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    textColor="primary"
                >
                    {tabs.map((tab) => (
                        <Tab key={tab.id} label={tab.value} />
                    ))}
                </Tabs>
                <Box className={classes.dashboardLink}>
                    <Link to={PATH_OTC.dashboard}>
                        <span><KeyboardBackspaceOutlinedIcon /></span>
                        Back to Dashboard
                    </Link>
                </Box>
            </Box>
            {/* Tab Panels */}
            <Box className={classes.rightSection}>
                {sections.map((section, index) => (
                    <TabPanel key={section.id} value={value} index={index}>
                        {section.value}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
}

export default VerticalTabsWithSections;

import { gql } from 'graphql-request';

export const CUSTOMER_PAYOUT = () => gql`
  mutation (
    $amount: Float!
    $token: Float!
    $crypto_type: String!
    $transaction_type: String!
    $wallet_address: String!
    $mode_of_contact: String!
    $blockchain: String!
  ) {
    customer_payout(
      data: {
        amount: $amount
        blockchain: $blockchain
        token: $token
        crypto_type: $crypto_type
        transaction_type: $transaction_type
        wallet_address: $wallet_address
        mode_of_contact: $mode_of_contact
      }
    ) {
      success
      message
      payout_id
    }
  }
`;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const popupStyle = makeStyles((theme:Theme) => ({
    popStyle: {
        textAlign: 'center',
    },
    modelStyle: {
        '& .MuiDialog-paper': {
            width: '545px',
            borderRadius: '14px',
            padding: '40px 0px 120px 0px',
            textAlign: 'center',
            [theme.breakpoints.between('xs', 'sm')]: {
                width: '100%',
                margin: '0px',
                borderRadius: '0px',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
            },
            '& img': {
                margin: '0px auto',
            },
            '& h2': {
                color: 'rgba(31, 31, 31, 1)',
                fontFamily: 'Inter-Bold',
                marginTop: '30px',
            },
            '& h6': {
                color: 'rgba(143, 143, 143, 1)',
                marginTop: '2px',
            },
            '& h5': {
                padding: '0px 35px 0px 0px',
                textAlign: 'right',
                '& svg': {
                    color: 'rgba(158, 158, 158, 1)',
                    fontSize: '30px',
                    cursor: 'pointer',
                },
            },
        },
    },
}));

export default popupStyle;

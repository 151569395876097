const ERRORS = {
    EMAIL_ERROR: 'Invalid email format',
    PASSWORD_ERROR: 'Password must contain 8-20 letters, one capital letter, one small letter, a numeric value and a special character',
    USERNAME_ERROR: 'Please enter a valid User Name.',
    LASTNAME_ERROR: 'Please enter a valid Last Name',
    PHONE_ERROR: 'Invalid phone numer',
    DOB_PERSONAL_ERROR: 'Please enter a valid DOB',
    // AccountInfo
    CURRENCY_ERROR: 'Please Select the Currency',
    BANK_INSTRUCT: 'Please Enter the valid Bank Instruction',
    BANK_ADDRESS: 'Please Enter Valid Bank Address',
    INSTU_NUM: 'Please Enter Valid Instution Number',
    TRANSIT_NUM: 'Please Enter Valid Transit Number',
    ACCOUNT_NUM: 'Please Enter Valid Account Number',
    SWIFT_NUM: 'Please Enter Valid Swift/BIC Code',
    ACC_HOLDER_NAME: 'Please enter valid account holder Name',
    ACC_HOLDER_LAST_NAME: 'Please enter valid account holder last name',
    MANDATORY_FIELD: 'Mandatory field',
    INSTITUTION_NUMBER_ERROR: 'Institution number should contain 3 digits',
    TRANSIT_NUMBER_ERROR: 'Transit number should contain 5 digits',
    NUMERIC_FIELD_ERROR: 'This field only accepts numbers',
    LIMIT_EXCEED_ERROR: 'Amount cannot exceed 20,000,000',
};

export {
    ERRORS,
};

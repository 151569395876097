import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import PaginationStyle from './PaginationTable.style';
import { PAGINATION } from '../../helper/constants';

interface Props {
    totalPages: number,
    handlePageChange: (page: number) => void,
    view?: string,
    resultPerPage: number,
    inputText?: string,
    totalCount?: number,
}

export default function Pagination(props: Props) {
    const {
        totalPages, handlePageChange, view, resultPerPage, inputText, totalCount,
    } = props;
    const classes = PaginationStyle();
    const getPage = localStorage.getItem('pages');
    const initialPageValue = getPage ? parseInt(getPage, 10) : 1;
    const [currentPage, setCurrentPage] = useState(initialPageValue);

    useEffect(() => {
        setCurrentPage(initialPageValue);
    }, [view]);

    useEffect(() => {
        setCurrentPage(initialPageValue);
    }, [inputText]);

    const handleCurrentPageChange = (type: string) => {
        let newPage;
        switch (type) {
        case PAGINATION.NEXT: newPage = currentPage + 1;
            break;
        case PAGINATION.PREVIOUS: newPage = currentPage - 1;
            break;
        case PAGINATION.LAST: newPage = totalPages;
            break;
        case PAGINATION.FIRST: newPage = 1;
            break;
        default: newPage = currentPage;
        }
        setCurrentPage(newPage);
        handlePageChange(newPage);
        localStorage.setItem('pages', `${newPage}`);
    };

    const previousEnabled = !(currentPage === 1);
    const nextEnabled = !(currentPage === totalPages) && !!totalPages;

    return (
        <div>
            <Box className={classes.paginationStyle}>
                <Box className={classes.middlePaginationContent}>
                    <FirstPageIcon
                        className={previousEnabled ? classes.blueIcon : ''}
                        onClick={previousEnabled ? () => handleCurrentPageChange(PAGINATION.FIRST) : undefined}
                    />
                    <NavigateBeforeIcon
                        className={previousEnabled ? classes.blueIcon : ''}
                        onClick={previousEnabled ? () => handleCurrentPageChange(PAGINATION.PREVIOUS) : undefined}
                    />
                    <Box className={classes.pageCount}>
                        <p>Page</p>
                        {/* <TextField value={totalPages > 0 ? currentPage : totalPages} disabled /> */}
                        <p className={classes.valueOutOff}>{totalPages > 0 ? currentPage : totalPages}</p>
                        <p className={classes.pageCountOff}>of</p>
                        <p>{totalPages}</p>
                    </Box>
                    <NavigateNextIcon
                        className={nextEnabled ? classes.blueIcon : ''}
                        onClick={nextEnabled ? () => handleCurrentPageChange(PAGINATION.NEXT) : undefined}
                    />
                    <LastPageIcon
                        className={nextEnabled ? classes.blueIcon : ''}
                        onClick={nextEnabled ? () => handleCurrentPageChange(PAGINATION.LAST) : undefined}
                    />
                </Box>
                <Box className={classes.leftPaginationContent}>
                    <p>Rows per page</p>
                    <div className={classes.resultOutOfStyle}>
                        <p>{resultPerPage}</p>
                    </div>
                </Box>
                <Box className={classes.rightPaginationContent}>
                    <p>Total Count</p>
                    <div className={classes.resultOutOfStyle}>
                        <p>{totalCount}</p>
                    </div>
                </Box>
            </Box>
        </div>
    );
}

import QrScanner from 'qr-scanner'; // Assuming qr-scanner is properly installed
import {
    useCallback, useEffect, useRef, useState,
} from 'react';

import QrFrame from '../../../assets/qr-frame.svg';
import toast from 'react-hot-toast';

interface QrReaderProps {
    handleScanSuccess: (data: string) => void;
}

function QrReader(props: QrReaderProps) {
    const { handleScanSuccess } = props;
    const scanner = useRef<QrScanner | null>(null);
    const videoEl = useRef<HTMLVideoElement>(null);
    const qrBoxEl = useRef<HTMLDivElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);
    const [scannedResult, setScannedResult] = useState<string>('');
    const qrCodeErrorCount = useRef(0);

    const onScanSuccess = useCallback((result:any) => {
        setScannedResult(result?.data);
        handleScanSuccess(result?.data);
        scanner.current?.stop();
    }, [handleScanSuccess]);

    const onScanFail = useCallback(() => {
        if (qrCodeErrorCount.current === 0) {
            toast.error('No QR code found');
        }
        qrCodeErrorCount.current += 1; // Increment error count
    }, []);

    useEffect(() => {
        const startScanner = async () => {
            try {
                if (!videoEl.current) return;

                // Reset video element's srcObject to null
                videoEl.current.srcObject = null;

                scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
                    onDecodeError: onScanFail,
                    preferredCamera: 'environment',
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                    overlay: qrBoxEl.current || undefined,
                });

                await scanner.current.start(); // Use await to handle async operation
                setQrOn(true);
            } catch (error) {
                toast.error('Camera not found.');
                setQrOn(false);
            }
        };

        const timer = setTimeout(() => {
            startScanner();
        }, 500);

        return () => {
            scanner.current?.stop();
            clearTimeout(timer);
            scanner.current = null;
        };
    }, [handleScanSuccess, onScanSuccess, onScanFail]);

    useEffect(() => {
        if (!qrOn) {
            toast.error(
                'Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.',
            );
        }
    }, [qrOn]);

    return (
        <div className="qr-reader">
            <video ref={videoEl} controls={false}>
                <track kind="captions" src="path_to_captions_file.vtt" srcLang="en" label="English" default />
                Your browser does not support the video tag.
            </video>

            {videoEl && (
                <div ref={qrBoxEl} className="qr-box">
                    <img
                        src={QrFrame}
                        alt="Qr Frame"
                        width={256}
                        height={256}
                        className="qr-frame"
                    />
                </div>
            )}
            {scannedResult && (
                <p>
                    Scanned Result:
                    {scannedResult}
                </p>
            )}
        </div>
    );
}

export default QrReader;

export const SECRET_PRIVATE_KEYS = `-----BEGIN ENCRYPTED PRIVATE KEY-----
MIIFHzBJBgkqhkiG9w0BBQ0wPDAbBgkqhkiG9w0BBQwwDgQIVCFGMb+3i2QCAggA
MB0GCWCGSAFlAwQBKgQQyHctCRnoiQWcB3bTyoT+tASCBNAMsn7HjZVdvHVB3wId
H0uqgNRFZjVCavaideiyccLzYP87+69yau579TTc6QwxHLQWiVAf4A+VL7lMoF1n
c/wIlivtn9a4+NB96SbaLFDftU2oPEBG+5yssTNP4R6Q8t/EIsYjcfy8eOfg2DbM
adZ1WlPQKx9mhcORqmYI6wh0l2s+8w8ogvSyJ8yi3BpY+ta4s7EAtig0vj8rzKrO
zRAZjQh6GUNx/42e+aUmuAX1b6fEcdR7bE1387buirbrSu/8UbDlGuPOOb2aQeQ4
8KZ0Yk3rWmyzQPVrxXj+lBC0tXm5xztOG1luC5tiM3Mr8gv+5dvk30Ap0KLA0TUi
mvbUgB4XpW1MDgFB/heGCwWnqLppvMnP20Wp/eA6TixplHGtmSYteoe00ynkiiCg
URtKgpMh1P2/TvGkpWRF0BeBIf+RxX9FiaevKRN68JM+WkK5U83OJR69z2+l+VSo
JX0L2fYo+BpKF9yoEyCMM7aUhngXD1/G7MWDZcEoRNrMwxzvKFGOyhLw0U6ACbLj
zE8H4oYIYz1ZK93WwwwM7FhVJkWoQycEoDIqpmUzWqZ5sIJmlXZS6IZCnoBD6WNB
sKQXGcnnU397SkayZ17lkZXIWDhuQOtVoEIxNHkOngnhFpuwNK/YNIen51rXeDwY
eJTWwdhi2h87IUMNxFYhBc8uzxwj2NCKpXmgEth0ePvrBicl0uhsgLLbGF5faX21
y2cHaTvZlqt5EKbKwPXqggy5nhP4w8IItQgKW46aN5/kV2zJlmgmpz1+0ptropyk
xuRyziwe2z4n1WnRM6sMb6qsYl5QClJYzVkkjWLmtjXAGGXwnyoQ7gJp96wTBcSG
5usCsFc8PTqrnxeER7swjrgtZCpjhhR+YG3hfFOXfaal0KQaslsndKSXhyOoIJhZ
Dc0vo8HM7y3gN2wgo1LBUanZR0ILn+/kiYuoDAh08pKYzMdLY26an8jJcNyeSxUs
xQ/bNKS6VetnUR6L8WTczSEZXPnSsAB10Eccioecgl+d3kS7FN3gocJuq6bBJimo
7ea/WXm71aO4Ob6i6EDIUADteraB0eBDGOG65sEnrRfMCyKzSHEUd04kF/AzPl2n
fZbG9dqZXC/HVNYNc07ZGn/YdNBLTXCt4FkrHzYgW/FP45BIy/ShbSu9KINOFpRJ
dtx1XJUsPaHhjziffYkNn9XggPDe+hUlRaEyezPHfCF91v39uPbo/3GApw/D7kSk
DmkxtVBNLRoNwu6/31zQBV96Xe26eo/x3DL7JxIYI3aatdV8KVfhTV8zYFsSUIp0
yI2wzQJZ5t0GriEGVO2WoQVZ6hwvDHGCfC5XI8CZtddUW4aeC8X1l1Y14BzxraBQ
wa7SufWgoodf/79RBaTaNYJvdLJywhS/WJ+jT1D3p4uADl4OlAPUo1uJdPIM7Rq+
dx3u6iKbu0PjjLify25TKuZl5aIeU2xNOtDn3FBjOHhhy6XhbuFxIHLL92UljKbV
n24uJPX5pZKjPFsoGqLg8o6b9LnOa58KzrizGSbmunDifLPfl1Yn9vSHy6yqtsWA
oXV9rQ1klh+bEIuvDws3m+XbXzO2drqPU7yL50iEghfHPPk+JCo20QrxeMJRsYvD
NY3pdfxH65JoxB4v3zZOKhngLg==
-----END ENCRYPTED PRIVATE KEY-----
`;

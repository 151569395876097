const getStatusColor = (data: string): string => {
    switch (data) {
    case 'Buy':
        return 'buy';
    case 'Sell':
        return 'sell';
    case 'CANCELLED':
        return 'cancelled-color';
    case 'COMPLETED':
        return 'completed-color';
    case 'PENDING':
        return 'pending-color';
    default:
        return '';
    }
};

const statusHandle = (data: string): string => {
    switch (data) {
    case 'COMPLETED':
        return 'Completed';
    case 'PENDING':
        return 'Pending';
    case 'CANCELLED':
        return 'Cancelled';
    default:
        return '';
    }
};

const transactionStatus = (data: string): string | null => {
    switch (data) {
    case 'Buy':
        return 'Buy';
    case 'Sell':
        return 'Sell';
    default:
        return null;
    }
};

const getStatusDetailsColor = (status: string): string => {
    switch (status) {
    case 'user_info':
        return 'completed-color';
    case 'verified':
        return 'completed-color';
    case 'pending':
        return 'pending-color';
    case 'completed':
        return 'completed-color';
    case 'Cancelled':
        return 'cancelled-color';
    case 'Inactive':
        return 'cancelled-color';
    default:
        return '';
    }
};

const statusHandleDetails = (vall: string): string | null => {
    switch (vall) {
    case 'user_info':
        return 'Active';
    case 'pending':
        return 'Pending';
    case 'completed':
        return 'Completed';
    case 'Cancelled':
        return 'Cancelled';
    case 'In Active':
        return 'InActive';
    case 'verified':
        return 'Onboarded';
    case 'In process':
        return 'In process';
    default:
        return null;
    }
};

export {
    getStatusColor, statusHandle, transactionStatus, getStatusDetailsColor, statusHandleDetails,
};

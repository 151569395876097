/**
 * Function to encode values before storing in local storage
 * @param token
 * @returns
 */
function encodeToken(token: string) {
    return window.btoa(token);
}

/**
 * Function to decode values taken from local storage
 * @param token
 * @returns
 */
function decodeToken(token: string) {
    return window.atob(token);
}

/**
 * Set Encoded Data to localstorage
 * @param item
 * @returns : data | undefined
 */
function setEncodedItemDataToLocalStorage(item: string, value: string) {
    const encodedValue = encodeToken(value);
    localStorage.setItem(item, encodedValue);
}

/**
 * Get Encoded Item from local storage
 * @param item
 * @returns : data | undefined
 */
function getDecodedItemDataFromLocalStorage(item: string) {
    const value = localStorage.getItem(item);
    if (!value) return value;
    let returnValue = null;
    try {
        returnValue = decodeToken(value);
    } catch (error) {
        return null;
    }
    return returnValue;
}

/**
 * Function to encode items and set in local storage
 * @param item
 * @param value
 */
function setItemInLocalStorage(item: string, value: string) {
    localStorage.setItem(item, value);
}

/**
 * Function to fetch and decode items from local storage
 * @param item
 * @returns
 */
function getItemFromLocalStorage(item: string) {
    return localStorage.getItem(item);
}

/**
 * Get Item from local storage
 * @param item
 * @returns : data | undefined
 */
function getItemDataFromLocalStorage(item: string) {
    return localStorage.getItem(item);
}

function removeItemFromStorage(item: string) {
    localStorage.removeItem(item);
}

/**
 * Function to set access token in local storage
 * @returns
 */
function setAccessTokenInLocalStorage(token: string) {
    return setItemInLocalStorage('access_token', encodeToken(token));
}

/**
 * Function to set refresh token in local storage
 * @returns
 */
function setRefreshTokenInLocalStorage(token: string) {
    return setItemInLocalStorage('refresh_token', token);
}

/**
 * Function to fetch access token from local storage
 * @returns
 */
function getAccessTokenFromLocalStorage() {
    return decodeToken(getItemFromLocalStorage('access_token') || '');
}

/**
 * Function to fetch refresh token from local storage
 * @returns
 */
function getRefreshTokenFromLocalStorage() {
    return getItemFromLocalStorage('refresh_token');
}

function clearAccessTokenOnLogout() {
    localStorage.clear();
}

function JSONParse<T>(string: string | undefined) {
    return JSON.parse(string || '{}') as T;
}

/**
 * Helper function to decode JWT token and return its contents
 * @param token The JWT token to decode
 * @returns The decoded contents of the JWT token
 */
interface IDecodedTokenData {
    id: string;
    iat: number;
    exp: number;
}

function getDecodeTokenData(token: string): IDecodedTokenData {
    // Decode the token
    const decodedToken: IDecodedTokenData = JSONParse<IDecodedTokenData>(atob(token.split('.')[1]));
    return decodedToken;
}

/**
 * Function to check if a JWT token is expired
 * @param token The JWT token to check
 * @returns True if the token is expired, false otherwise
 */
function isTokenExpired(token: string): boolean {
    const decodedToken = getDecodeTokenData(token);

    // Get the current time in seconds
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    // Check if the token's expiration time (exp) is in the past
    return decodedToken.exp < currentTimeInSeconds;
}

export {
    getAccessTokenFromLocalStorage,
    getRefreshTokenFromLocalStorage,
    setAccessTokenInLocalStorage,
    setRefreshTokenInLocalStorage,
    clearAccessTokenOnLogout,
    JSONParse,
    getItemDataFromLocalStorage, setItemInLocalStorage, removeItemFromStorage,
    setEncodedItemDataToLocalStorage,
    getDecodedItemDataFromLocalStorage,
    getDecodeTokenData,
    isTokenExpired,
};

// refreshTokenHelper.js
import { useEffect } from 'react';
import { useGQLQuery } from './useGQLQuery';
import { QueryErrorResponse } from '../types/UserDetailType';
import toast from 'react-hot-toast';
import userQueries from '../queries/user';
import {
    getAccessTokenFromLocalStorage, getDecodeTokenData, getRefreshTokenFromLocalStorage, setAccessTokenInLocalStorage, setRefreshTokenInLocalStorage,
} from '../helper/storageHelper';
import { useQueryClient } from '@tanstack/react-query';
import { KEY_CONF, URL_CONF } from '../helper/constants';
import { useNavigate } from 'react-router-dom';
import { useLogout } from './useLogout';
import { PATH_AUTH } from '../routers/path';

const useRefreshTokenHandler = (isAuthenticated: boolean) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const logout = useLogout();
    const storedRefreshToken = getRefreshTokenFromLocalStorage() ?? '';

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        if (message) {
            toast.error(message);
        }
        return true;
    };

    const {
        data, refetch, isError, error,
    } = useGQLQuery(
        [KEY_CONF.REFRESH_TOKEN],
        userQueries.REFRESH_TOKEN(storedRefreshToken),
        {},
        {
            enabled: false,
        },
        URL_CONF.AUTHENTICATION,
    );

    useEffect(() => {
        const path = PATH_AUTH.auth;
        if (isError && error !== null) {
            logout();
            queryClient.clear();
            navigate(path);
            handleApiError(error);
        }
    }, [error, isError, logout, navigate, queryClient]);

    const handleLoginSuccess = (newAccessToken: string, newRefreshToken: string) => {
        setAccessTokenInLocalStorage(newAccessToken);
        setRefreshTokenInLocalStorage(newRefreshToken);
    };

    useEffect(() => {
        if (data && data.refresh_token) {
            const { access_token: newAccessToken, refresh_token: newRefreshToken } = data.refresh_token;
            if (newAccessToken || newRefreshToken) {
                handleLoginSuccess(newAccessToken, newRefreshToken);
            }
            queryClient.resetQueries({ queryKey: ['refreshToken'], exact: true }).catch(() => {});
        }
    }, [data, queryClient]);

    useEffect(() => {
        let tokenExpirationTimer: NodeJS.Timeout | null = null;

        const refreshAccessToken = () => {
            try {
                const token = getAccessTokenFromLocalStorage();
                const decodedToken = getDecodeTokenData(token);
                const tokenExpiration = decodedToken.exp;
                const currentTime = Date.now() / 1000;
                const timeToRefresh = 60; // Seconds before token expiration to refresh
                if (tokenExpiration && tokenExpiration - currentTime < timeToRefresh) {
                    refetch();
                }
            } catch (err) {
                toast.error('Error refreshing access token');
            }
        };

        if (isAuthenticated) {
            tokenExpirationTimer = setInterval(refreshAccessToken, 58001); // Check every 58 sec
        }

        return () => {
            if (tokenExpirationTimer) clearInterval(tokenExpirationTimer);
        };
    }, [isAuthenticated, refetch]);
};

export default useRefreshTokenHandler;

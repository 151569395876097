import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../../types/UserDetailType';

export interface UserDataState {
  value: UserInfo | null
}

const initialState: UserDataState = {
    value: null,
};

export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        addUserData: (state, action: PayloadAction<UserInfo>) => {
            state.value = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { addUserData } = userDataSlice.actions;

export default userDataSlice.reducer;

import CryptoJS from 'crypto-js';
import { SECRET_PRIVATE_KEYS } from '../secret_keys/private.key';

const encryptString = (value: string | number) => {
    // if value is number then first convert it to string
    if (typeof (value) === 'number') {
        const data = value.toString();
        const encryptedMsg = CryptoJS.AES.encrypt(data, SECRET_PRIVATE_KEYS).toString();
        return encryptedMsg;
    }
    // if value is string
    const encryptedMsg = CryptoJS.AES.encrypt(value, SECRET_PRIVATE_KEYS).toString();
    return encryptedMsg;
};

const decryptString = (value: string) => {
    const bytes = CryptoJS.AES.decrypt(value, SECRET_PRIVATE_KEYS);
    const decryptedMsg = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedMsg;
};

export { encryptString, decryptString };
